import { Box, Button, Paper } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput, TextInput } from "../../components/form/TextInput";
import { Meter } from "../../models/Types";
import HouseCombo from "../houses/HouseCombo";
import TariffCombo from "../project/tariff/TariffCombo";
import { isMobile } from "../../utils";

export function meterParse(d?: any) {
    const dto = (d || {}) as Meter;
    d?.CheckDate && (dto.CheckDate = new Date(d.CheckDate));
    d?.NextCheck && (dto.NextCheck = new Date(d.NextCheck));
    return dto;
}

export class MeterForm extends AbstractTypedDataForm<Meter> {
    protected getTypeId(): string {
        return 'Meter';
    }
    protected getTypeName(): React.ReactNode {
        return 'Счетчик';
    }

    buildButtons() {
        return [];
    }

    protected createEntity() {
        return meterParse();
    }

    protected buildItems(entity?: Meter | undefined): React.ReactNode {
        return <>
            <Paper sx={isMobile() ? { flex: 1 } : { minWidth: 500 }}>
                <FormTextInput required={true} label="Номер" name="Number" form={this} entity={entity} />
                <FormTextInput label="Наименование" name="Name" form={this} entity={entity} />
                <TextInput
                    required={true}
                    value={entity?.HouseId}
                    label="Дом"
                    name="HouseId"
                    onChange={this.onChange}
                    validator={v => v ? undefined : 'Необходимо выбрать дом'}
                    refValidator={val => this.addValidator('HouseId', 'HouseId', v => val(v))}
                    component={(onChange, error) => <HouseCombo
                        name="HouseId" 
                        error={!!error}
                        value={entity?.HouseId}
                        onChange={onChange} />} />
                <DatePicker label="Дата поверки" value={moment(entity?.CheckDate)} onChange={v => entity!.CheckDate = v?.toDate() || new Date()} />
                <DatePicker label="Следующая поверка" value={moment(entity?.NextCheck)} onChange={v => entity!.NextCheck = v?.toDate() || new Date()} />

                <TextInput
                    required={true}
                    value={entity?.Tariffs}
                    label="Тариф(ы)"
                    name="Tariffs"
                    onChange={this.onChange}
                    validator={v => (v as number[])?.length ? undefined : 'Необходимо выбрать тариф(ы)'}
                    refValidator={val => this.addValidator('Tariffs', 'Tariffs', v => val(v))}
                    component={(onChange, error) => <TariffCombo
                        name="Tariffs"
                        multiple={true}
                        error={!!error}
                        value={entity?.Tariffs}
                        onChange={onChange} />} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    {<Button variant="contained" type="submit">Сохранить</Button>}
                </Box>
            </Paper>
        </>;
    }
}