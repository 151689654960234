import { Box, ButtonProps, Chip, ChipProps, FormControl, FormLabel, OutlinedInput, Paper } from "@mui/material";
import React, { ReactNode } from "react";
import { Contact, Person } from "../../models/Types";
import ImageInput from "../ImageInput";
import { DataGridApiRef } from "../data/ApiRef";
import { AbstractTypedDataForm } from "../data/DataForm";
import { FormTextInput } from "../form/TextInput";
import { Contacts } from "./Contacts";
import { PersonUser } from "./PersonUser";
import './person.css';

export class PersonForm<T extends Person = Person, TProps = {}, TState = {}> extends AbstractTypedDataForm<T, TProps, TState & { contacts: Contact[] }> {
    protected contactsApi?: DataGridApiRef<T>
    // protected responseEntityProp = 'entity';
    protected getTypeId() {
        return 'Person';
    }

    protected createEntity() {
        return new Person() as T;
    }

    clear(): void {
        this.setState({
            id: 0,
            contacts: [],
            entity: new Person()
        });
    }

    protected responseToState(response: any) {
        const state = super.responseToState.call(this, response);
        state && (state.contacts = state.entity.getContacts());
        return state;
    }

    save(e: React.FormEvent<HTMLFormElement>) {
        this.state.entity.setContacts(this.state.contacts);
        return super.save.call(this, e);
    }

    buildButtons(entity?: Person) {
        return [];
    }

    buildDeleteButton(text?: ReactNode, props?: ButtonProps) {
        return super.buildDeleteButton(text || 'Удалить пользователя', props);
    }

    protected buildPersonItems(entity?: T) {
        return [
            <>
                <FormTextInput required={true} label="Имя (обращение)" name="Name" form={this} entity={entity} />
                <FormTextInput label="Фамилия" name="Surname" form={this} entity={entity} />
                <FormTextInput label="Отчество" name="Patronomic" form={this} entity={entity} />
            </>,

            <Box sx={{ my: 1 }}>
                <Contacts contacts={this.state?.contacts} apiRef={x => this.contactsApi = x} />
            </Box>,

            <FormControl>
                <FormLabel>Примечание</FormLabel>
                <OutlinedInput name="description" multiline={true} defaultValue={entity?.Description} onChange={this.onChange} />
            </FormControl>,

            <PersonUser
                typeName={this.getTypeName()}
                contacts={this.state?.contacts}
                client={this.state?.entity}
                onCreate={userId => this.onChange({ target: { name: 'userId', value: userId } })}
                onAddClick={() => this.contactsApi?.add()} />
        ];
    }

    protected getStatuses(): ChipProps[] {
        return [];
    }

    protected buildItems(entity?: T) {
        return <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', overflow: 'hidden', gap: 2 }}>
            <Paper style={{ minWidth: 500, flex: 'none' }}>
                <Box sx={styles.statusChips}>
                    {this.getStatuses().map(x => <Chip size="small" {...x} />)}
                </Box>
                <ImageInput value="https://mui.com/static/images/buttons/burgers.jpg" title="Фото" />

                <Box sx={styles.buttonsBox}>
                    {entity && this.buildDeleteButton()}
                </Box>
            </Paper>

            <Paper sx={{ flex: 1 }}>
                {this.buildPersonItems(entity)}

                <Box sx={styles.buttonsBox}>
                    {this.buildSaveButton()}
                </Box>
            </Paper>
        </Box>
    }
}

const styles = {
    buttonsBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1
    },
    statusChips: {
    }
}

Object.assign(styles.statusChips, styles.buttonsBox);