import { Box, Button, FormControl, FormLabel, OutlinedInput } from '@mui/material';
import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import Proxy from '../../models/Proxy';

export default function PasswordForm() {
    const [p, sp] = useState('');
    const [p1, sp1] = useState('');
    const [p2, sp2] = useState('');

    function passwordInput(v: string, sv: Dispatch<SetStateAction<string>>, t: string) {
        return <FormControl>
            <FormLabel required>{t}</FormLabel>
            <OutlinedInput type="password" value={v} onChange={e => sv(e.target.value)} required />
        </FormControl>;
    }

    function changePassword(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (p1 == p2) {
            Proxy.post('ChangePassword', {
                currentPassword: p,
                newPassword: p1
            }).then(x => {
                if (x.success && x.result.Succeeded) {
                    sp('');
                    sp1('');
                    sp2('');
                }
            });
        }
    }

    return <Box component="form" sx={{ p: 1 }} onSubmit={changePassword}>
        {passwordInput(p, sp, 'Текущий пароль')}
        {passwordInput(p1, sp1, 'Новый пароль')}
        {passwordInput(p2, sp2, 'Подтверждение нового пароля')}

        <Button disabled={!p1 || (p1 != p2)} type="submit" variant="contained" sx={{ mt: 1 }}>Сменить пароль</Button>
    </Box>;
}