import { AutoFixHighRounded, Receipt } from '@mui/icons-material';
import { Button, Toolbar } from '@mui/material';
import { useRef } from 'react';
import { ListApiRef } from '../../components/data/ApiRef';
import DataTable, { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import TablePaper from '../../components/form/TablePaper';
import Proxy from '../../models/Proxy';
import { Bill, ProvidedService } from '../../models/Types';
import { psBillCollumns } from '../bill';
import { billDetailParse } from '../bill/BillForm';
import HouseCombo, { houseStore } from '../houses/HouseCombo';
import ServiceCombo, { serviceStore, serviceTextRenderer } from '../project/service/ServiceCombo';
import { ProvidedServiceForm, providedServiceParse } from './ProvidedServiceForm';

export const columns: ColumnData<ProvidedService>[] = [{
    label: 'Услуга',
    routerLink: true,
    name: 'ServiceId',
    store: serviceStore,
    filter: (onChange) => <ServiceCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => serviceStore.data?.filter(x => x.Id == val).map(serviceTextRenderer)[0]
}, {
    label: 'Заказчики',
    name: 'HouseIds',
    routerLink: true,
    type: 'array',
    store: houseStore,
    filter: (onChange) => <HouseCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => (val as number[])?.map(v => houseStore.data?.filter(x => x.Id == v).map(x => x.Value)).join(', ')
}, {
    label: 'Количество',
    name: 'Count',
    type: 'numeric',
    filter: true
}, {
    label: 'Сумма',
    name: 'Sum',
    type: 'numeric',
    filter: true
    }];

function BillsTab({ id }: { id: number }) {
    const apiRef = useRef<ListApiRef<Bill>>();

    return <TablePaper sx={{ overflow: 'hidden', p: 0 }} >
        <Toolbar>
            <Button startIcon={<AutoFixHighRounded />} onClick={() => {
                Proxy.post('BillCreateForProvidedService', [id]).then(x => {
                    if (x.success) {
                        apiRef.current?.load();
                    } else {
                        debugger;
                    }
                });
            }}>Сформировать начисления</Button>
        </Toolbar>
        <DataTable<Bill>
            typeId="Bill"
            columns={psBillCollumns}
            cmd={'BillForProvidedService?id=' + id}
            apiRef={x => apiRef.current = x}
            dataParser={billDetailParse} />
    </TablePaper>;
}

export default function ProvidedServiceView() {
    return <DataView<ProvidedService>
        title="Оказанная услуга"
        titleMultiple="Оказанные услуги"
        typeId="ProvidedService"
        form={[{
            title: 'Основное',
            element: (id, apiRef) => <ProvidedServiceForm key="main" id={id} apiRef={apiRef} />
        }, {
            title: 'Начисления',
            icon: <Receipt />,
            element: (id, apiRef) => <BillsTab id={id} />
        }]}
        tableProps={{
            columns,
            multiple: true,
            dataParser: providedServiceParse
        }} />;
}