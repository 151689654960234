import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, LinearProgress } from "@mui/material";
import { useState } from "react";
import Proxy from "../../models/Proxy";
import { Exception } from "../../models/Types";

export interface DeleteDialogProps extends DialogProps {
    typeName?: React.ReactNode
    typeId?: string
    cmd?: string

    entityId?: number
    entityName?: React.ReactNode
    title?: string
    text?: string

    deleteText?: string

    onSuccess?: Function
    onError?: (ex?: Exception) => void
    onCancel?: Function
}

function getMessage(ex: Exception) {
    if (ex.type == 'ForeignKey') {
        return `Имеются зависимые объекты. Удаление запрещено`;
    } else {
        return ex.message;
    }
}

export default function DeleteDialog(props: DeleteDialogProps) {
    const [open, setOpen] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [ex, setEx] = useState<Exception>();

    function close() {
        setLoading(false);
        setOpen(false);
        if (ex) {
            props.onError && props.onError(ex);
        } else {
            props.onCancel && props.onCancel();
        }
    }

    const handleDelete = () => {
        setLoading(true);
        Proxy.post(props.cmd || (props.typeId! + 'Delete'), props.entityId)
            .then(x => {
                if (x.success) {
                    props.onSuccess && props.onSuccess();
                    close();
                } else {
                    setEx(x as Exception);
                }
            }, x => {
                debugger;
                props.onError && props.onError();
                close();
            })
    }

    return <Dialog {...props} open={open} aria-labelledby="delete-dialog-title" aria-describedby="delete-dialog-description">
        <DialogContent>
            <LinearProgress sx={{ p: 0, mt: -2.5, mx: -3 }} style={{ opacity: loading && !ex ? 1 : 0, position: "absolute", width: '100%' }} />

            <DialogContentText id="delete-dialog-description">
                {ex ? <>
                    В процессе удаления объекта {props.typeName} «{props.entityName}» возникла ошибка:
                    <br />
                    {getMessage(ex)}
                </> :
                    (props.text || <>Удалить {props.typeName} «{props.entityName}»?</>)}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDelete} variant="contained" color="error" autoFocus disabled={loading}>{props.deleteText || 'Удалить'}</Button>
            <Button onClick={close}>{loading || ex ? 'Закрыть' : 'Отмена'}</Button>
        </DialogActions>
    </Dialog>
}