import { Box, Button, Paper, SxProps, Theme } from "@mui/material";
import React from "react";
import { AbstractTypedDataForm } from "../../../components/data/DataForm";
import { FormNumberInput, FormTextInput } from "../../../components/form/TextInput";
import { Service } from "../../../models/Types";
import { isMobile } from "../../../utils";

export class ServiceForm extends AbstractTypedDataForm<Service> {
    private ki = 0;

    protected getTypeName(): React.ReactNode {
        return 'Услуга';
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            ...this.props.sx,
            flexDirection: 'row',
            alignItems: 'flex-start'
        };
    }

    buildButtons() {
        return [];
    }

    protected getTypeId(): string {
        return 'Service';
    }

    protected buildItems(entity?: Service | undefined): React.ReactNode {
        return <>
            <Paper sx={isMobile() ? { flex: 1 } : { minWidth: 500 }}>
                <FormTextInput key={`Name-${this.ki}`} required={true} label="Наименование" name="Name" form={this} entity={entity} />
                <FormTextInput key={`Unit-${this.ki}`} required={true} label="Единицы измерения" name="Unit" form={this} entity={entity} />
                <FormNumberInput key={`Price-${this.ki}`} required={true} label="Стоимость за единицу" name="Price" form={this} entity={entity} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <Button variant="contained" type="submit">Сохранить</Button>
                </Box>
            </Paper>
        </>;
    }
}