import RemoteCombo, { RemoteComboProps, configureSuggestStore } from "../../components/form/RemoteCombo";
import { MeterTariff } from "../../models/Types";

const cmd = 'MeterTariffList';
const valueField = 'Tariff';

export const meterTariffStore = configureSuggestStore<MeterTariff>(cmd, valueField, 30 * 60);

export default function MeterTariffCombo({ name, value, onDataLoad, onChange, allowAdd, multiple, filter }: RemoteComboProps<MeterTariff>) {
    return <RemoteCombo<MeterTariff>
        name={name}
        store={meterTariffStore}
        typeId="Tariff"
        allowAdd={allowAdd}
        value={value}
        multiple={multiple}
        onDataLoad={onDataLoad}
        onChange={onChange}
        filter={filter} />;
}