import { HolidayVillage, LocationOn } from '@mui/icons-material';
import route from '../../Router';
import { AnalyticFilterFuncs } from '../../components/data/AnalyticFilter';
import { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import { guides } from '../../guides/Guide';
import { Location } from '../../models/Types';
import { LocationForm } from './LocationForm';
import { LocationMap } from './LocationMap';

route.register('lat', undefined, undefined, x => parseFloat(x));
route.register('lon', undefined, undefined, x => parseFloat(x));

guides.locationsGuide = [
    {
        target: '[data-list-view-typeid="Location"] .datatable-toolbar',
        disableBeacon: true,
        title: 'Локации',
        content: <>В разделе <b>Локации</b> можно посмотреть дома, камеры, проходные или любые геолокационные точки.
            <p>В <b>Заявках</b> можно указывать <b>Локации</b> для любой заявки</p>
        </>,
    },
    {
        target: '.datatable-toolbar > [role="group"]',
        placementBeacon: 'right',
        title: 'Карта',
        content: <><b>Локации</b> можно просматривать на <b>карте</b>, а также добавлять и редактировать, выбирая метки на <b>карте</b>.
        </>,
    }
];

const columns: ColumnData<Location>[] = [{
    label: 'Наименование',
    name: 'Name',
    filter: true,
    routerLink: true
}, {
    label: 'Адрес',
    name: 'Address',
    filter: true
    }];

const analyticFilters: { [key: string]: AnalyticFilterFuncs } = {
    Всего: {
        percent: (d, i, a) => 1
    },
    Домов: {
        percent: (d, i, a) => d?.value ? (d?.sub || 0) / d?.value : 0
    }
};

export default function LocationView() {
    return <DataView<Location>
        title="Локация"
        titleMultiple="Локации"
        typeId="Location"
        //guide="locationsGuide"
        /**
        analyticFilter={{
            url: "LocationAnalyticFilter",
            percent: (d, item, all) => analyticFilters[item.key]?.percent ? analyticFilters[item.key].percent!(d, item, all) : 0,
            sub: (x, item) => item.key === 'Домов' ? (x?.sub || 0) + ' домохозяйств' : null,
            value: x => x?.value,
            items: [{
                icon: <LocationOn fontSize="large" />,
                key: 'Всего'
            }, {
                color: "success",
                icon: <HolidayVillage fontSize="large" color="info" />,
                key: 'Домов'
            }]
        }}
         */
        modes={[{
            mode: 'list',
            text: <>Список</>
        }, {
            mode: 'board',
            text: <>Карта</>,
            view: () => <LocationMap />
        }]}
        form={(id, apiRef) => <LocationForm id={id} apiRef={apiRef} lat={route.get('lat')} lon={route.get('lon')} />}
        tableProps={{
            columns: columns
        }} />;
}