import { HomeRepairService, ManageHistoryRounded, PriceChange } from "@mui/icons-material";
import { guides } from "../../guides/Guide";
import Logo from "../../icons/Logo";
import { IAppMenu } from "../../nav/AppRoute";
import TenantView from "./TenantForm";
import ServiceView from "./service";
import TariffView from "./tariff";
import PeriodicServiceView from "./periodicService";

guides.projectMenu = [
    {
        target: '#positions-menu',
        placementBeacon: 'right',
        placement: 'right',
        title: 'Должности',
        content: <><b>Должности</b> разделяют права <b>Сотрудников</b> в ЖК с помощью ключей доступа.
            <p>Например, для доступа к <b>Клиентам</b>, <b>Группам</b> и Управлению ЖК</p>
            <p>При создании нового ЖК по умолчанию создаются преднастроенные <b>Должности</b>
                <ul>
                    <li><i>Руководитель</i> - с полным доступом к всем функциям</li>
                    <li><i>Администратор</i> - с доступами к ведению <b>Клиентов</b> и <b>Групп</b></li>
                    <li><i>Преподователь</i> - с доступом к ведению закрепленными за ним <b>Групп</b></li>
                </ul>
            </p>
        </>,
    },
].map(x => {
    return {
        ...x,
        placementBeacon: 'right',
        placement: 'right',
    }
});

const projectMenu: IAppMenu = [
    {
        path: "",
        title: 'Основное',
        icon: <Logo />,
        element: () => <TenantView idRoute="subpage" />,
    },/**
    {
        id: 'positions-menu',
        path: "positions/*",
        icon: <LockPerson />,
        tenanted: true,
        title: 'Должности',
        element: () => <Positions />,
    },/**/
    {
        id: 'tariff-menu',
        path: "tariff/*",
        icon: <PriceChange />,
        tenanted: true,
        title: 'Тарифы',
        element: () => <TariffView />,
    },
    {
        id: 'service-menu',
        path: "service/*",
        icon: <HomeRepairService />,
        tenanted: true,
        title: 'Услуги',
        element: () => <ServiceView />,
    },
    {
        id: 'periodicService-menu',
        path: "periodicService/*",
        icon: <ManageHistoryRounded />,
        tenanted: true,
        title: 'Регулярные услуги',
        element: () => <PeriodicServiceView />,
    }
];

projectMenu.forEach(x => x && (x.path = 'project/' + x.path));

export default projectMenu;