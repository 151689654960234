import { PersonForm } from "../../components/person/PersonForm";
import { Person } from "../../models/Types";

export class ProfileForm extends PersonForm {
    protected fetchNew(): boolean {
        return true;
    }

    protected getTypeId(): string {
        return 'Person';
    }

    protected getCmdGet(): string {
        return this.getTypeId() + 'Current';
    }

    protected buildPersonItems(entity?: Person) {
        var result = super.buildPersonItems(entity);

        return result.filter((x, i) => i < 2);
    }

    buildButtons(entity?: Person) {
        var result = super.buildButtons(entity);

        return result.filter((x, i) => !i);
    }
}

export default function ProfileView() {
    return <ProfileForm id={0} sx={{ p: 1 }} />
}