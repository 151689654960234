import { Box, Button, Paper, SxProps, Theme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import React from "react";
import { AbstractTypedDataForm } from "../../../components/data/DataForm";
import { FormNumberInput, FormTextInput } from "../../../components/form/TextInput";
import { Tariff } from "../../../models/Types";
import { isMobile } from "../../../utils";

export function tariffParse(d?: any) {
    const dto = (d || {}) as Tariff;
    d?.StartDate && (dto.StartDate = new Date(d.StartDate));
    return dto;
}


export class TariffForm extends AbstractTypedDataForm<Tariff> {
    private ki = 0;

    protected getTypeName(): React.ReactNode {
        return 'Тариф';
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            ...this.props.sx,
            flexDirection: 'row',
            alignItems: 'flex-start'
        };
    }

    buildButtons() {
        return [];
    }

    protected getTypeId(): string {
        return 'Tariff';
    }

    protected createEntity() {
        return tariffParse();
    }

    protected buildItems(entity?: Tariff | undefined): React.ReactNode {
        return <>
            <Paper sx={isMobile() ? { flex: 1 } : { minWidth: 500 }}>
                <FormTextInput key={`Name-${this.ki}`} required={true} label="Наименование" name="Name" form={this} entity={entity} />
                <FormTextInput key={`Unit-${this.ki}`} required={true} label="Единицы измерения" name="Unit" form={this} entity={entity} />
                <FormNumberInput key={`Price-${this.ki}`} required={true} label="Стоимость за единицу" name="Price" form={this} entity={entity} />
                <FormNumberInput key={`Norm-${this.ki}`} required={true} label="Норма" name="Norm" form={this} entity={entity} />
                <DatePicker label="Начало действия тарифа" value={moment(entity?.StartDate)} onChange={v => entity!.StartDate = v?.toDate() || new Date()} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <Button variant="contained" type="submit">Сохранить</Button>
                </Box>
            </Paper>
        </>;
    }
}