import { AddRounded, AutoFixHighRounded, HomeRepairServiceRounded, Receipt } from '@mui/icons-material';
import { Button, Toolbar } from '@mui/material';
import { useRef } from 'react';
import { ListApiRef } from '../../components/data/ApiRef';
import DataTable, { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import TablePaper from '../../components/form/TablePaper';
import Proxy from '../../models/Proxy';
import { Bill, ProvidedService, Request } from '../../models/Types';
import { psBillCollumns } from '../bill';
import HouseCombo, { houseStore } from '../houses/HouseCombo';
import InhabitantCombo, { inhabitantStore } from '../inhabitants/InhabitantCombo';
import ServiceCombo, { serviceStore, serviceTextRenderer } from '../project/service/ServiceCombo';
import { columns as psCollumns } from '../providedService';
import { RequestForm, requestParse } from './RequestForm';

const columns: ColumnData<Request>[] = [{
    label: 'Наименование',
    name: 'Name',
    routerLink: true
}, {
    label: 'Услуги',
    name: 'ServiceIds',
    type: 'array',
    store: serviceStore,
    filter: (onChange) => <ServiceCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => (val as number[])?.map(v => serviceStore.data?.filter(x => x.Id == v).map(serviceTextRenderer)).join(', ')
}, {
    label: 'Дом(а)',
    name: 'HouseIds',
    type: 'array',
    store: houseStore,
    filter: (onChange) => <HouseCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => (val as number[])?.map(v => houseStore.data?.filter(x => x.Id == v).map(x => x.Value)).join(', ')
}, {
    label: 'Дата',
    name: 'CreateDate',
    filter: true,
    type: 'date',
}, {
    label: 'Заявители',
    name: 'ApplicantId',
    store: inhabitantStore,
    filter: (onChange) => <InhabitantCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => inhabitantStore.data?.find(x => x.Id == val)?.Value
    }];

const recPsCollumns = psCollumns.map(x => x.routerLink ? { ...x, routerLink: false } : x);

function Services({ id }: { id: number }) {
    const ref = useRef<ListApiRef<ProvidedService>>();

    function fillServices() {
        Proxy.post('ProvidedServiceFillForRequest', id).then(x => x && ref.current?.load());
    }

    return <TablePaper>
        <Toolbar>
            <Button startIcon={<AutoFixHighRounded />} onClick={fillServices}>Заполнить услуги</Button>
            <Button startIcon={<AddRounded />} color="primary" sx={{ ml: 'auto' }}>Добавить</Button>
        </Toolbar>
        <DataTable<ProvidedService> typeId="ProvidedService" apiRef={x => ref.current = x} columns={recPsCollumns} cmd={'ProvidedServiceForRequest?id=' + id} />
    </TablePaper>
}

export default function RequestView() {
    return <DataView<Request>
        title="Заявка"
        titleMultiple="Заявки"
        typeId="Request"
        form={[{
            title: 'Основное',
            element: (id, apiRef) => <RequestForm key="main" id={id} apiRef={apiRef} />
        }, {
            title: 'Услуги',
            icon: <HomeRepairServiceRounded />,
            element: id => <Services id={id} />
        }, {
            title: 'Начисления',
            icon: <Receipt />,
            element: (id, apiRef) => <TablePaper sx={{ overflow: 'hidden', p: 0 }} >
                <Toolbar>
                    <Button startIcon={<AutoFixHighRounded />}>Сформировать начисления</Button>
                </Toolbar>
                <DataTable<Bill> typeId="Bill" columns={psBillCollumns} cmd={'BillForRequest?id=' + id} />
            </TablePaper>
        }]}
        tableProps={{ columns, dataParser: requestParse }} />;
}