import { hierarchicalMenu } from './AppRoute';
import Drawer from './Drawer';
import { Typography } from '@mui/material';
import Logo from '../icons/Logo';
import { theme } from '../theme';

export default function AppDrawer({ open }: { open: boolean }) {
    return <Drawer key={'drawer' + open} id="right-menu" open={open} menu={hierarchicalMenu} showLogo={true} title="Главное меню">
        <div style={{ flex: 1, zIndex: -1 }}>
            <div style={{ width: '80%', margin: '10%', bottom: 25, position: 'absolute', display: 'flex', justifyContent: 'center' }}>
                <Logo color={theme.palette.action.selected} />
            </div>
        </div>

        {!open ? null : <Typography variant="caption" style={{ textAlign: 'center' }}>Версия 0.1.0</Typography>}
    </Drawer>
}