import { ChevronLeft, ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material';
import { CSSObject, Collapse, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Drawer as MuiDrawer, DrawerProps as MuiDrawerProps, Theme, Toolbar, styled } from '@mui/material';
import { useState } from 'react';
import { UrlRoute } from '../Router';
import Title from '../icons/Title';
import { useTenant, useUser } from '../models/Hooks';
import { isMobile } from '../utils';
import { IAppMenu, IAppMenuItem, IAppMenuNode, useAppRoute } from './AppRoute';
import { theme } from '../theme';

export const drawerWidth = isMobile() ? '100%' : 180;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => (isMobile() ? {
    display: 'none'
} : {
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: isMobile() ? 0 : theme.spacing(5.5),
    [theme.breakpoints.up('sm')]: {
        width: isMobile() ? 0 : theme.spacing(6.5),
    },
});

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        overflowY: 'auto',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '&.MuiDrawer-docked': {
            overflowX: 'visible',
            ...(isMobile() ? {
                position: 'fixed',
                height: '100%',
                zIndex: 1
            } : undefined)
        },
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        '& > .MuiDrawer-paper': {
            overflow: 'visible',
        },
        '& .MuiListItemIcon-root > svg': {
            width: '2rem',
            height: '2rem'
        },
        '& .MuiTypography-root': {
            whiteSpace: 'normal'
        }
    }),
);

const StyledCollapsibleNode = styled(ListItem)({
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
        width: 0,
        height: 0,
        transition: 'all 100ms cubic-bezier(0.4, 0, 0.2, 1)'
    },
    '& .MuiTypography-root': {
        fontWeight: 'bold',
    },
    '&:hover .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
    },
    '&:hover *': {
        color: '#000',
    }
});

interface DrawerProps extends MuiDrawerProps {
    title: string,
    showLogo?: boolean
    menu: IAppMenu,
    open: boolean
}

interface BaseListProps {
    op: boolean,
    open: boolean,
    route: UrlRoute,
    currentItem: IAppMenuItem,
    isAdmin: boolean,
    mob: boolean,
    handleDrawerClose: () => any
}

function MenuExpandableNode({ x, op, open, isAdmin, mob, handleDrawerClose, route, currentItem }: { x: IAppMenuNode } & BaseListProps) {
    const [exp, setExp] = useState(true);
    const menuList = <MenuList op={op} open={exp} menu={x.children!} isAdmin={isAdmin} mob={mob} handleDrawerClose={handleDrawerClose} route={route} currentItem={currentItem} />;

    if (!op) {
        return <>
            <Divider key={x.id} />
            {menuList}
        </>;
    }

    return <>
        <StyledCollapsibleNode id={x.id} key={x.title?.toString()} onClick={() => setExp(!exp)} >
            {exp ? <ExpandLess color="disabled" /> : <ExpandMore color="disabled" />}
            <ListItemText secondary={x.title} secondaryTypographyProps={{ sx: styles.listItemSecondary(op) }} />
        </StyledCollapsibleNode>

        <Collapse key={'collapse' + x.id} in={exp} timeout="auto" unmountOnExit>
            {menuList}
        </Collapse>
    </>
}

function MenuList({ op, open, menu, isAdmin, mob, handleDrawerClose, route, currentItem }: { menu: (IAppMenuNode | null)[] } & BaseListProps) {
    return <List sx={{ padding: 0 }}>
        {menu
            .filter(x => !x?.tenanted || isAdmin)
            .filter(x => !x || x.icon || x.title)
            .map((x, i) => x?.path !== undefined ? <ListItem key={x.title?.toString()}
                id={x.id}
                disablePadding
                sx={{ display: 'block' }}
                onClick={() => { mob && handleDrawerClose(); route.setPath(x.path!.replace(/\/\*$/, '').replace(/\/:.*$/, '')); }}>
                <ListItemButton sx={styles.listItemButton(op, currentItem == x)} >
                    <ListItemIcon sx={styles.listItemIcon(op, currentItem == x)}>{x.icon}</ListItemIcon>
                    <ListItemText primary={x.title} primaryTypographyProps={{ sx: styles.listItemText(op, x, currentItem == x) }} />
                </ListItemButton>
            </ListItem> :
                x?.title ? x.children ? <MenuExpandableNode x={x} route={route} currentItem={currentItem} op={op} open={open} isAdmin={isAdmin} mob={mob} handleDrawerClose={handleDrawerClose} /> :
                    <ListItem id={x.id} key={x.title?.toString()}>
                        <ListItemText secondary={x.title} secondaryTypographyProps={{ sx: styles.listItemSecondary(op) }} />
                    </ListItem> :
                    <Divider key={i} />)}
    </List>
}

export default function Drawer({ title, menu, open, children, showLogo, ...props }: DrawerProps) {
    const [op, setOpened] = useState(open);
    const handleDrawerOpen = () => setOpened(true);
    const handleDrawerClose = () => setOpened(false);
    const [route, path, current, currentItem] = useAppRoute(menu, () => op && isMobile() && setOpened(false));

    const tenant = useTenant();
    const user = useUser();
    const isAdmin = tenant?.OwnerId == user.Id;

    const mob = isMobile();
    return <StyledDrawer variant="permanent" open={op} PaperProps={{ sx: { position: 'relative', border: 'none' } }} {...props}>
        {showLogo ? <Toolbar style={{ padding: 0, justifyContent: 'center', position: 'relative' }}>
            <Title />
            {mob ? null : <IconButton onClick={op ? handleDrawerClose : handleDrawerOpen} sx={{
                backgroundColor: '#fff',
                borderWidth: 1,
                borderStyle: 'solid',
                position: 'absolute',
                right: 'calc(-.75rem - 1px)',
                width: '1.5rem',
                height: '1.5rem',
                zIndex: 10000
            }}>
                {op ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}
            </IconButton>}
        </Toolbar> : null}

        <MenuList op={op} open={open} menu={menu} isAdmin={isAdmin} mob={mob} handleDrawerClose={handleDrawerClose} route={route} currentItem={currentItem} />

        {children}
    </StyledDrawer>;
}

const styles: { [name: string]: (open: boolean, ...props: any) => React.CSSProperties } = {
    listItemIcon: (open: boolean, current: boolean) => {
        return {
            transition: 'all 225ms',
            zoom: .75,
            minWidth: 0,
            mr: open ? 1.5 : 0,
            opacity: current ? 1 : .8,
            color: current ? 'primary.main' : undefined,
            justifyContent: 'center',
        }
    },
    listItemButton: (open: boolean, current: boolean) => {
        return {
            minHeight: 24,
            backgroundColor: current ? theme.palette.background.paper : 'none',
            justifyContent: open ? 'initial' : 'center',
            flexDirection: open ? 'row' : 'column',
            py: .6,
            mx: 1,
            borderRadius: 3
        }
    },
    listItemText: (open: boolean, item: any, current: boolean) => {
        return {
            transition: 'all 225ms',
            opacity: open ? current ? 1 : .8 : 0.8,
            //maxWidth: open ? 'auto' : 0,
            color: current ? 'primary.main' : item?.color,
            fontSize: open ? '0.875rem' : '0.66rem',
            fontWeight: 500,
            lineHeight: 1.57143
        }
    },
    listItemSecondary: (open: boolean) => {
        return {
            opacity: open ? .7 : 0,
            maxWidth: open ? 'auto' : 0,
            color: 'secondary',
            fontWeight: 300,
            fontSize: '0.9rem'
        }
    }
}