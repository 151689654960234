import { Paper, styled } from "@mui/material";

const TablePaper = styled(Paper)(({ theme }) => ({
    '&': {
        overflow: 'hidden'
    },
    '& > .MuiPaper-root': {
        margin: 0
    }
}));

export default TablePaper;