import { Payment, Person, Security } from "@mui/icons-material";
import { IAppMenu } from "../../nav/AppRoute"
import ProfileView from "./ProfileForm";
import PasswordForm from "./PasswordForm";
import Account from "./account";

const profileMenu: IAppMenu = [
  {
    path: '',
    title: 'Основное',
    icon: <Person />,
    element: () => <ProfileView />,
  },
  {
    path: 'security',
    title: 'Доступ',
    icon: <Security />,
    element: () => <PasswordForm />,
  },
  {
    id: 'account-menu',
    path: 'account',
    title: 'Баланс',
    icon: <Payment />,
    element: () => <Account />,
  }
];

profileMenu.forEach(x => x && (x.path = 'profile/' + x.path));

export default profileMenu;