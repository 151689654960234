import { Step } from 'react-joyride';

const tenantCreate: Step[] = [/**
    {
        target: '#tenant-selector',
        content: 'Откройте список Проектов',
        placementBeacon: 'right-end'
    },/**/
    {
        target: '#tenant-selector-new',
        title: 'Создайте ЖК',
        placementBeacon: 'right',
        content: 'ЖК - отдельный аккаунт в котором можно вести независимый список дововладений и домовладельцев, сотрудников, расчетов и многое другое',
    }
];

export default tenantCreate;