import { LatLngTuple, icon } from "leaflet";
import markerIconUrl from "leaflet/dist/images/marker-icon.png";
import markerShadowUrl from "leaflet/dist/images/marker-shadow.png";
import route from "../../Router";

route.register('lat', undefined, undefined, x => parseFloat(x));
route.register('lon', undefined, undefined, x => parseFloat(x));

export interface ILocation {
    lat?: number
    lon?: number
}

export const markerIcon = icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: markerIconUrl,
    shadowUrl: markerShadowUrl
});

export const defaultCenter: LatLngTuple = [55.794438, 49.111451];

export const styles = {
    map: {
        flex: 1,
        overflow: 'hidden',
        minHeight: 350
    }
}