import { Box, Button, InputAdornment, Paper, SxProps, TextField, Theme } from "@mui/material";
import { LatLng } from "leaflet";
import React from "react";
import { AddressApiRef, AddressPanel } from "../../components/address/AddressPanel";
import { ILocation } from "../../components/address/common";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormNumberInput, FormTextInput, TextInput } from "../../components/form/TextInput";
import { House, IHasAddress } from "../../models/Types";
import PeriodicServiceTypeCombo from "../project/periodicService/PeriodicServiceTypeCombo";
import PeriodicServiceCombo from "../project/periodicService/PeriodicServiceCombo";

export interface HouseDto extends House, IHasAddress {
}

export class HouseForm extends AbstractTypedDataForm<HouseDto, ILocation, {}> {
    private typedLocation?: any
    private addressRef?: AddressApiRef;
    private ki = 0;

    protected getTypeName(): React.ReactNode {
        return 'Локация';
    }

    componentWillReceiveProps(n: Readonly<{ id: number } & ILocation>, nextContext: any): void {
        super.componentWillReceiveProps(n, nextContext);
        const p = this.props;
        if (p.id == n.id && n.lat && n.lon && (p.lat != n.lat || p.lon != n.lon)) {
            this.addressRef?.setLatLon(new LatLng(n.lat, n.lon));
        }
    }

    clear(): void {
        this.addressRef?.clear();
        super.clear();
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            ...this.props.sx,
            overflow: 'auto',
            flexDirection: 'row',
            alignItems: 'flex-start'
        };
    }

    buildButtons() {
        return [];
    }

    protected getTypeId(): string {
        return 'House';
    }

    protected createEntity() {
        return new House() as HouseDto;
    }

    protected buildItems(entity?: HouseDto | undefined): React.ReactNode {
        return <>
            <Paper sx={{ minWidth: 500 }}>
                <FormTextInput key={`street-${this.ki}`} required={true} label="Улица" name="Street" form={this} entity={entity} />

                <FormTextInput key={`number-${this.ki}`} required={true} label="Номер дома" name="Number" form={this} entity={entity} />

                {entity && <AddressPanel entity={entity} apiRef={x => this.addressRef = x} onAdressChange={s => {
                    if (s?.data && entity) {
                        var nu = false;
                        if (s.data.street !== entity.Street) {
                            entity.Street = s.data.street as string;
                            nu = true;
                        }
                        if (s.data.house !== entity.Number) {
                            entity.Number = s.data.house as string;
                            nu = true;
                        }
                        if (nu) {
                            this.ki++;
                            this.forceUpdate();
                        }
                    }
                }} />}

                <FormNumberInput key={`Area-${this.ki}`} label="Площадь" name="Area" form={this} entity={entity} inputProps={{
                    endAdornment: <InputAdornment position="end">кв.м.</InputAdornment>
                }} />

                <FormNumberInput key={`Residents-${this.ki}`} label="Количество жильцов" name="Residents" form={this} entity={entity} inputProps={{
                    endAdornment: <InputAdornment position="end">чел.</InputAdornment>
                }} />

                <TextInput
                    value={entity?.PeriodicServices || []}
                    label="Услуги"
                    name="PeriodicServices"
                    onChange={this.onChange}
                    component={(onChange, error) => <PeriodicServiceCombo
                        name="PeriodicServices"
                        multiple={true}
                        error={!!error}
                        value={entity?.PeriodicServices || []}
                        onChange={onChange} />} />

                <TextField label="Баланс" disabled={true} value={entity?.Balance} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <Button variant="contained" type="submit">Сохранить</Button>
                </Box>
            </Paper>
        </>;
    }
}