import { Box, FormControl, FormLabel, Paper } from "@mui/material";
import route from "../../Router";
import { FormApiRef } from "../../components/data/ApiRef";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput } from "../../components/form/TextInput";
import { fetchUser, getUser } from "../../models/Identity";
import { getTenantId } from "../../models/Tenant";
import { Tenant } from "../../models/Types";
import { useRoute, useTenantId } from "../../models/Hooks";

export class TenantForm extends AbstractTypedDataForm<Tenant> {
    protected getTypeId(): string {
        return 'Tenant';
    }

    protected createEntity() {
        return new Tenant();
    }

    protected buildItems(entity?: Tenant) {
        return <Paper sx={{ p: 2 }} style={{ width: 500, flex: 'none' }}>
            <FormTextInput form={this} entity={entity} name="Name" label="Наименование" />

            <FormControl>
                <FormLabel>Тема интерфейса</FormLabel>
            </FormControl>

            <Box sx={{ display: 'flex', marginTop: 2 }}>
                {(!entity?.Id || entity?.OwnerId == getUser().Id) ? super.buildButtons(entity) : null}
            </Box>
        </Paper>;
    }

    buildButtons(entity?: Tenant) {
        return [];
    }
}

function apiRef(api?: FormApiRef) {
    api?.onSave(() => fetchUser());
    api?.onDelete(() => fetchUser());
}

export default function TenantView({ idRoute }: { idRoute: string }) {
    useRoute();
    useTenantId();
    const id = route.get(idRoute) || route.get('id') || getTenantId();
    return <TenantForm id={parseInt(id) || 0} apiRef={apiRef} />;
}