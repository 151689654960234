import { MenuRounded } from '@mui/icons-material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import { isAuthentificated, listenUser } from './models/Identity';
import AppBar from './nav/AppBar';
import { menu, useAppRoute } from './nav/AppRoute';
import TenantSelector from './nav/TenantSelector';
import UserMenu from './nav/UserMenu';
import Login from './screens/Login';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import moment from 'moment';
import 'moment/locale/ru';
import { useState } from 'react';
import RouterGuide from './guides/RouterGuide';
import { useRoute } from './models/Hooks';
import AppDrawer from './nav/AppDrawer';
import { theme } from './theme';
import { isMobile } from './utils';

moment.locale('ru');

function Body() {
  const [route, path, current, menuItem] = useAppRoute(menu);
  return <>{menuItem?.element && menuItem.element()}</>;
}

export default function App() {
    const [open, setOpened] = useState(!isMobile());
    const [auth, setAuth] = useState(isAuthentificated());
    useRoute(() => open && isMobile() && setOpened(false))

    listenUser(() => setAuth(isAuthentificated()));

    function buildCloseDrawer(open: boolean) {
        return <IconButton onClick={() => setOpened(!open)}>
            <MenuRounded />
        </IconButton>;
    };

    function buildLayout() {
        return <Box id="ukroot-wrapper" sx={{ display: 'flex', flexDirection: 'row-reverse', height: '100vh', overflow: 'hidden', gap: 2 }}>
            <CssBaseline />

            <Box component="main"
                sx={{ overflowY: isMobile() ? undefined : 'hidden', overflowX: 'hidden' }}
                style={{ flexGrow: 1, display: 'flex', flex: 1, flexDirection: 'column' }}>
                {isMobile() ? null : <AppBar position="relative" open={open} color="transparent" elevation={0}>
                    <Toolbar style={isMobile() ? { paddingLeft: 0 } : undefined}>
                        <TenantSelector />
                        <div style={{ flex: 1 }} />
                        <UserMenu />
                    </Toolbar>
                </AppBar>}

                <Box className="body"
                    sx={isMobile() ? open ? { display: 'none' } : styles.body!.mobile : styles.body!.main}
                    style={{ overflow: isMobile() ? undefined : 'hidden' }}>
                    <Body />
                </Box>
            </Box>

            <AppDrawer key={'drawer' + open} open={open} />

            <RouterGuide />
        </Box>;
    }

    return <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {auth ? buildLayout() : <Login />}
        </LocalizationProvider>
    </ThemeProvider>;
}

const styles = {
    body: {
        main: { mr: 2, mb: 2 },
        mobile: { display: 'flex', flex: 1, flexDirection: 'column', flexGrow: 1, position: 'relative' } 
    }
}

Object.assign(styles.body.main, styles.body.mobile);
(styles.body.mobile as any)!.overflow = 'hidden';