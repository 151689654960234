import { useTheme } from "@mui/material";

export default function Logo({ color, ...props }: any) {
    const theme = useTheme();

    color || (color = theme.palette.primary.main);
    return <svg version="1.0" height="2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163 107">
        <g transform="translate(0.000000,107.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
            <path d="M4 1055 c-12 -30 7 -35 139 -35 l132 0 252 -252 253 -252 2 -255 c3
-248 4 -256 23 -256 19 0 20 8 23 235 l2 234 238 -237 238 -238 159 3 c152 3
160 4 160 23 0 19 -8 20 -150 23 l-151 2 -239 240 -240 240 245 245 244 244
136 3 c127 3 135 4 135 23 0 19 -8 20 -145 23 l-144 3 -243 -243 -243 -242 -2
239 c-3 232 -4 240 -23 240 -19 0 -20 -8 -25 -238 l-5 -237 -240 240 -240 240
-143 0 c-115 0 -144 -3 -148 -15z"/>
        </g>
    </svg>;
}