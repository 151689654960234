import DataView from '../../components/data/DataView';
import { PersonListDto } from "../../components/person/PersonList";
import { InhabitantForm } from './InhabitantForm';
import { inhabitantListColumns } from './InhabitantList';

export default function InhabitantView() {
    return <DataView<PersonListDto>
        title="Жилец"
        titleMultiple="Жильцы"
        typeId="Inhabitant"
        form={(id, apiRef) => <InhabitantForm key="main" id={id} apiRef={apiRef} />}
        tableProps={{
            columns: inhabitantListColumns,
            dataParser: PersonListDto.parse
        }} />;
}