import { Step } from 'react-joyride';
import { userMenuGuide } from '../nav/UserMenu';
import { Typography } from '@mui/material';

const welcome = [
    {
        target: '#root',
        title: <>Добро пожаловать в <Typography component={p => <span {...p} />} color="primary">УК</Typography></>,
        content: 'Познакомьтесь с основными инструментами УК за несколько простых шагов!',
        styles: {
            tooltipContent: {
                textAlign: 'center'
            }
        },
        placement: "center"
    },
    {
        target: '#desktop-menu',
        title: <>Рабочий стол</>,
        placement: 'right'
    },
    {
        target: '#schedule-menu',
        title: <>Расписание</>,
        placement: 'right'
    },
    {
        target: '#programs-menu',
        title: <>Программы</>,
        placement: 'right'
    },
    {
        target: '#locations-menu',
        title: <>Локации</>,
        placement: 'right'
    },
    ...userMenuGuide,
    {
        target: '#tenant-selector-new',
        title: 'Создайте свой ЖК',
        placementBeacon: 'right',
        content: <>
            ЖК - отдельный бизнес-аккаунт в котором можно вести независимый список домовладений и домовладельцев, сотрудников, расчетов и многое другое
        </>,
    }
] as Step[];

export default welcome;