import { ButtonProps, ChipProps } from "@mui/material";
import { TextInput } from "../../components/form/TextInput";
import { PersonForm } from "../../components/person/PersonForm";
import { Person } from "../../models/Types";
import HouseCombo from "../houses/HouseCombo";
import { ReactNode } from "react";
import { CurrencyRuble, Receipt } from "@mui/icons-material";

export class InhabitantForm extends PersonForm {
    protected getTypeId() {
        return 'Inhabitant';
    }

    protected getTypeName(): React.ReactNode {
        return 'Жилец';
    }

    protected getStatuses(): ChipProps[] {
        return [{
            label: '10.08.2024',
            icon: <Receipt />,
            color: 'error',
            title: 'Дата предыдщих переданных показаний - 10.08.2024. Просрочка с 25.09.2024'
        }, {
            label: '1.234',
            icon: <CurrencyRuble />,
            color: 'error'
        }];
    }

    buildDeleteButton(text?: ReactNode, props?: ButtonProps) {
        return super.buildDeleteButton(text || 'Удалить жильца', props);
    }

    protected buildPersonItems(entity?: Person) {
        const items = super.buildPersonItems.call(this, entity);
        items.splice(2, 0,
            <TextInput
                required={true}
                value={entity?.HouseIds}
                label="Дом(а)"
                name="HouseIds"
                onChange={this.onChange}
                validator={v => (v as number[])?.length ? undefined : 'Необходимо выбрать дом(а) жильца'}
                refValidator={val => this.addValidator('HouseIds', 'HouseIds', v => val(v))}
                component={(onChange, error) => <HouseCombo
                    name="HouseIds"
                    multiple={true}
                    error={!!error}
                    value={entity?.HouseIds}
                    onChange={onChange} />} />);

        return items;
    }
}