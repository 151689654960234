import RemoteCombo, { RemoteComboProps, configureSuggestStore } from "../../../components/form/RemoteCombo";
import { PeriodicService } from "../../../models/Types";

const cmd = 'PeriodicServiceList';
const valueField = 'Name';

export const periodicServiceStore = configureSuggestStore<PeriodicService>(cmd, valueField, 30 * 60);

export default function PeriodicServiceCombo({ name, value, onDataLoad, filter, onChange, allowAdd, multiple, selectFirstOnLoad }: RemoteComboProps<PeriodicService>) {
    return <RemoteCombo<PeriodicService>
        name={name}
        store={periodicServiceStore}
        typeId="PeriodicService"
        allowAdd={allowAdd}
        value={value}
        multiple={multiple}
        onDataLoad={onDataLoad}
        selectFirstOnLoad={selectFirstOnLoad}
        filter={filter}
        onChange={onChange} />;
}