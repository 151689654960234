import { Step } from "react-joyride";
import route from "../Router";
import { useRoute } from "../models/Hooks";
import Guide, { GuideProps, guides } from "./Guide";

route.register('guide');

export default function RouterGuide({ onClose, ...props }: GuideProps) {
    useRoute();

    const guide = route.get('guide');
    let steps = guide ? guides[guide] : null;
    if (steps && !steps[0].disableBeacon) {
        steps = [{ ...steps[0], disableBeacon: true } as Step].concat(steps.filter((x, i) => i));
    }

    return steps ? <Guide
        steps={steps}
        run={true}
        showProgress={true}
        onClose={e => {
            route.setState([['guide']]);
            onClose && onClose(e);
        }}
        {...props} /> : <></>;
}