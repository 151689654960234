import { ColumnData } from '../../../components/data/DataTable';
import DataView from '../../../components/data/DataView';
import { PeriodicServiceType, periodicServiceTypeMap } from '../../../models/Enums';
import { PeriodicService } from '../../../models/Types';
import { PeriodicServiceForm } from './PeriodicServiceForm';
import PeriodicServiceTypeCombo from './PeriodicServiceTypeCombo';

const columns: ColumnData<PeriodicService>[] = [{
    label: 'Наименование',
    name: 'Name',
    filter: true,
    routerLink: true
}, {
    label: 'Стоимость',
    name: 'Price',
    type: 'numeric',
    filter: true
}, {
    label: 'База для расчета',
    name: 'Type',
    type: 'numeric',
    filter: (onChange) => <PeriodicServiceTypeCombo allowClear={true} onChange={e => onChange(e.target.value)} />,
    renderer: v => periodicServiceTypeMap[v as PeriodicServiceType]
}];

export default function PeriodicServiceView() {
    return <DataView<PeriodicService>
        title="Регулярная услуга"
        titleMultiple="Регулярные услуги"
        newTitle="Новая регулярная услуга"
        typeId="PeriodicService"
        form={(id, apiRef) => <PeriodicServiceForm id={id} apiRef={apiRef} />}
        tableProps={{
            columns: columns
        }} />;
}