import { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import { Payment } from '../../models/Types';
import HouseCombo, { houseStore } from '../houses/HouseCombo';
import InhabitantCombo, { inhabitantStore } from '../inhabitants/InhabitantCombo';
import PaymentForm, { paymentParse } from './PaymentForm';

const columns: ColumnData<Payment>[] = [{
    label: 'Дом',
    name: 'HouseId',
    routerLink: true,
    store: houseStore,
    filter: (onChange) => <HouseCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => houseStore.data?.find(x => x.Id == val)?.Value
}, {
    label: 'Плательщик',
    name: 'PayerId',
    routerLink: true,
    store: inhabitantStore,
    filter: (onChange) => <InhabitantCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => inhabitantStore.data?.find(x => x.Id == val)?.Value
}, {
    label: 'Дата',
    name: 'CreateDate',
    type: 'date',
    filter: true
}, {
    label: 'Сумма',
    name: 'Sum',
    type: 'numeric',
    filter: true
}];

export default function PaymentView() {
    return <DataView<Payment>
        title="Платеж"
        titleMultiple="Платежи"
        typeId="Payment"
        form={(id, apiRef) => <PaymentForm key="main" id={id} apiRef={apiRef} />}
        tableProps={{ columns, dataParser: paymentParse }} />;
}