import { Box, Button, Paper } from "@mui/material";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormNumberInput, TextInput } from "../../components/form/TextInput";
import { ProvidedService } from "../../models/Types";
import HouseCombo from "../houses/HouseCombo";
import ServiceCombo, { serviceStore } from "../project/service/ServiceCombo";
import { isMobile } from "../../utils";
export function providedServiceParse(d?: any) {
    const dto = (d || {}) as ProvidedService;
    d?.CreateDate && (dto.CreateDate = new Date(d.CreateDate));
    return dto;
}

export class ProvidedServiceForm extends AbstractTypedDataForm<ProvidedService> {
    private _priceSet?: boolean;

    protected getTypeId(): string {
        return 'ProvidedService';
    }
    protected getTypeName(): React.ReactNode {
        return 'Оказанная услуга';
    }

    buildButtons() {
        return [];
    }

    protected createEntity() {
        return providedServiceParse();
    }

    protected responseToState(response: any): any {
        var res = super.responseToState(response);

        this._priceSet = !!res.entity.Sum;

        return res;
    }

    protected onFieldChange(field: string, value: any) {
        var res = super.onFieldChange(field, value);

        if (field === 'ServiceId' || field === 'Count') {
            let e = this.state.entity;
            if (!this._priceSet && e.ServiceId && e.Count) {
                let price = serviceStore.data?.find(b => b.Id === e.ServiceId)?.Obj?.Price;
                if (price) {
                    super.onFieldChange('Sum', price * e.Count);
                    this._priceSet = false;
                    this.forceUpdate();
                }
            }
        } else if (field === 'Sum') {
            this._priceSet = true;
        }

        return res;
    }

    protected buildItems(entity?: ProvidedService | undefined): React.ReactNode {
        return <>
            <Paper sx={isMobile() ? { flex: 1 } : { minWidth: 500 }}>
                <TextInput
                    required={true}
                    value={entity?.ServiceId}
                    label="Услуга"
                    name="ServiceId"
                    onChange={this.onChange}
                    validator={v => v ? undefined : 'Необходимо выбрать услугу'}
                    refValidator={val => this.addValidator('ServiceId', 'ServiceId', v => val(v))}
                    component={(onChange, error) => <ServiceCombo
                        name="ServiceId" 
                        error={!!error}
                        value={entity?.ServiceId}
                        onChange={onChange} />} />

                <TextInput
                    value={entity?.HouseIds}
                    label="Домовладения"
                    name="HouseIds"
                    onChange={this.onChange}
                    component={(onChange, error) => <HouseCombo
                        name="HouseIds"
                        multiple={true}
                        error={!!error}
                        value={entity?.HouseIds}
                        onChange={onChange} />} />

                <FormNumberInput label="Количество" name="Count" form={this} entity={entity} />
                <FormNumberInput key={'sum' + entity?.ServiceId + '-' + entity?.Count} label="Сумма" name="Sum" form={this} entity={entity} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    {<Button variant="contained" type="submit">Сохранить</Button>}
                </Box>
            </Paper>
        </>;
    }
}