import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { IEntity } from "../../models/Types";
import { ListApiRef } from "./ApiRef";

export interface ViewMode<T extends IEntity> {
    mode: string,
    text: React.ReactNode,
    view?: (apiRef:(api?: ListApiRef<T>) => void) => React.ReactNode
}

export function buildViewModes<T extends IEntity>(modes?: ViewMode<T>[], viewMode?: string, setViewMode?: ((viewMode: string) => void)) {
    if (modes && modes.length > 1) {
        return <ToggleButtonGroup exclusive value={viewMode} onChange={(e, v) => setViewMode && setViewMode(v)} sx={{ marginLeft: 1 }}>
            {modes.map(x => <ToggleButton value={x.mode} disabled={viewMode == x.mode} color={viewMode == x.mode ? 'success' : undefined}>{x.text}</ToggleButton>)}
        </ToggleButtonGroup>;
    }
}