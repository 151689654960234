import { PaletteColorOptions, alpha, createTheme, getContrastRatio } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';

const defaultPalette = createPalette({});

const buttonBase = defaultPalette.text.primary;
const buttonMain = alpha(buttonBase, 0.7);

declare module "@mui/material/styles" {
    interface PaletteOptions {
        button?: PaletteColorOptions;
    }
}

declare module '@mui/material' {
    interface ButtonPropsColorOverrides {
        button: true
    }
}

export const theme = createTheme({
    palette: {
        button: {
            main: buttonMain,
            light: alpha(buttonBase, 0.5),
            dark: alpha(buttonBase, 0.9),
            contrastText: getContrastRatio(buttonMain, '#fff') > 4.5 ? '#fff' : '#111',
        },
        primary: {
            main: '#0075A2'
        },
        secondary: {
            main: '#1C252E',
            light: '#FBF8F3'
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F4F6F8'
                }
            }
        },
        MuiToolbar: {
            defaultProps: {
                color: 'transparent'
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#F4F6F8'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'rgba(145, 158, 171, 0.16) 0px 1px 2px 0px',
                    margin: 2
                },
                rounded: {
                    borderRadius: '1rem'
                },
                root: {
                    '& > .MuiFormControl-root:first-child': {
                        marginTop: '1rem'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 500,
                    borderRadius: 0,
                    '&.Mui-selected': {
                        color: "secondary",
                        fontWeight: 600
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                size: "small"
            }
        },
        MuiToggleButton: {
            defaultProps: {
                size: "small"
            }
        },
        MuiOutlinedInput: {/**
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    backgroundColor: '#fff'
                },
                input: {
                    backgroundColor: '#fff',
                    //fontSize: '1.1735rem'
                }
            },/**/
            defaultProps: {
                size: "small",
                fullWidth: true,
                minRows: 2,
                maxRows: 6
            }
        },
        MuiTextField: {
            defaultProps: {
                size: 'small'
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginLeft: 0
                }
            }
        },
        MuiFormLabel: {/**
            styleOverrides: {
                root: {
                    marginTop: 8,
                    fontWeight: 500,
                    fontSize: '1.0745rem'
                }
            }/**/
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true
            }
        },
        MuiButton: {
            defaultProps: {
                color: 'button',
            },
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
                outlined: {
                    backgroundColor: '#fff'
                }
            }
        }
    }
});