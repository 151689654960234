import { Button, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';

const ImageButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    height: 200,
    width: 200,
    borderRadius: '50%',
    overflow: 'hidden',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.4,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.15,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

export default function ImageInput({ value, title, onChange, ...inputProps }: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
    const [v, sv] = React.useState(value as string || undefined);

    return <Box sx={{ display: 'flex', flexWrap: 'wrap', alignSelf: 'center' }}>
        <ImageButton
            onClick={e => {
                e.preventDefault();

                const input = document.createElement('input');
                input.type = 'file';
                input.click();
                input.onchange = (e: any) => {
                    var reader = new FileReader();

                    reader.onload = function (e: any) {
                        sv(e.target.result);
                        input.remove();
                    }

                    reader.readAsDataURL(e.target.files[0]);

                    onChange && onChange(e);
                }
            }}>
            <ImageSrc key={v} style={{ backgroundImage: `url(${v})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
                <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={(theme) => ({
                        position: 'relative',
                        p: 4,
                        pt: 2,
                        pb: `calc(${theme.spacing(1)} + 6px)`,
                    })}
                >
                    {title}
                    <ImageMarked className="MuiImageMarked-root" />
                </Typography>
            </Image>
        </ImageButton>
    </Box>
}