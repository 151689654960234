import { Add, FilterAltOffOutlined, FilterAltOutlined, Help, MenuRounded } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, OutlinedInput, Paper, SxProps, Theme, Toolbar, Typography } from "@mui/material";
import { ReactNode } from 'react';
import route from '../../Router';
import Guide, { guides } from '../../guides/Guide';
import { BaseHierarchicalEntity, IEntity } from '../../models/Types';
import { theme } from '../../theme';
import { genId, isMobile } from '../../utils';
import ObservableComponent from '../base/ObservableComponent';
import AnalyticFilter, { AnalyticFilterDto, AnalyticFilterProps } from './AnalyticFilter';
import { ListApiRef } from './ApiRef';
import { DataBreadcrumbs } from './DataBreadcrumbs';
import DataTable, { DataTableProps } from './DataTable';
import DataTree from './DataTree';
import { ViewMode, buildViewModes } from './ViewModes';
import './dataview.css';

route.register('hideForm', undefined, undefined, x => x && (x.toLowerCase() == 'true' || x == '1'));
route.register('parentId', undefined, undefined, x => x ? parseInt(x) : undefined);

export interface ListViewProps<TListDto extends IEntity, TAF extends AnalyticFilterDto = AnalyticFilterDto> {
    typeId?: string;
    title?: string;
    newTitle?: string;
    guide?: string;
    hideAnyFilter?: boolean;
    filterByColumns?: boolean
    titleMultiple?: React.ReactNode;
    modes?: ViewMode<TListDto>[];
    tableProps: DataTableProps<TListDto>;
    sx?: SxProps<Theme>
    prepend?: () => ReactNode;
    append?: () => ReactNode
    className?: string
    emptyContent?: React.ReactNode
    route?: {
        id?: string
        form?: string
        root?: string
        getId?: () => (number | undefined)
    }
    toggleMenuOpened?: () => any
    analyticFilter?: AnalyticFilterProps<TAF>
}

export interface DataListViewState {
    detailedFiter?: boolean
    anyFilter?: string
    viewMode?: string
    guide?: boolean
    showEmptyContent?: boolean
}

export interface DataListViewProps<TListDto extends IEntity, TAF extends AnalyticFilterDto = AnalyticFilterDto> extends ListViewProps<TListDto, TAF> {
    compact?: boolean
    hidden?: boolean
    apiRef?: (api?: ListApiRef<TListDto>) => void
}

function newTitle(title?: string) {
    if (!title) {
        return title;
    }

    let lc = title[title.length - 1].toLowerCase();
    let newText = 'Новый';
    switch (lc) {
        case 'я': case 'а': newText = 'Новая'; break;
        case 'е': newText = 'Новое'; break;
    }

    return <span><span className="newbutton-newtext">{newText}</span> {title}</span>;
}

export function getId<TListDto extends IEntity>(props: ListViewProps<TListDto>) {
    const id = (props.route?.getId ? props.route.getId() : route.get(props.route?.id || 'id'));
    return id === 'new' ? 0 : (id ? id as number : undefined);
}

export default class DataListView<
    TListDto extends IEntity,
    P extends DataListViewProps<TListDto> = DataListViewProps<TListDto>,
    S extends DataListViewState = DataListViewState>
    extends ObservableComponent<P, S> {

    protected api?: ListApiRef<TListDto>;
    protected id: string;

    constructor(p: P) {
        super(p);

        this.apiRef = this.apiRef.bind(this);
        this.onAfterLoad = this.onAfterLoad.bind(this);
        this.handleColumnFilter = this.handleColumnFilter.bind(this);

        this.id = genId('data-list-view');
        this.state = {
            viewMode: p.modes?.length ? p.modes[0].mode : undefined
        } as S;
    }

    componentWillReceiveProps(p: Readonly<P>, c: any) {
        if (!!p.hidden !== !!this.props.hidden) {
            this.forceUpdate();
        }

        super.componentWillReceiveProps && super.componentWillReceiveProps(p, c);
    }

    componentDidMount(): void {
        this._unlisten.push(route.listen(() => this.forceUpdate()));
    }

    protected apiRef(api?: ListApiRef<TListDto>) {
        this.api = api;
        this.props.apiRef && this.props.apiRef(api);
    }

    protected onAfterLoad(data: TListDto[], response: any) {
        this.props.tableProps?.onAfterLoad && this.props.tableProps?.onAfterLoad(data, response);
        this.setState({ showEmptyContent: !data.length && !!this.props.emptyContent });
    }

    protected buildView() {
        const mode = this.getMode();
        const route = this.props.route && {
            id: this.props.route.id,
            root: this.props.route.root
        }

        if (!mode?.view) {
            const props: DataTableProps<TListDto> = {
                typeId: this.props.typeId,
                showFilter: !!this.state.detailedFiter,
                rowSelector: "routerLink",
                routerParams: routeParams => {
                    isMobile() || routeParams.push(['hideForm', true]);
                    return routeParams;
                },
                ...this.props.tableProps,
                style: {
                    margin: 0,
                    borderTop: '1px solid ' + theme.palette.divider,
                    ...this.props.tableProps?.style,
                },
                onAfterLoad: this.onAfterLoad,
                route: route,
                selectedId: getId(this.props),
                apiRef: this.apiRef,
                anyFilter: this.state.anyFilter
            };

            var table = this.renderTable(props, mode);
            if (table) {
                return table;
            }
        }

        if (mode?.view) {
            return mode.view(this.apiRef);
        }
    }

    protected renderTable(props: DataTableProps<TListDto>, mode?: ViewMode<TListDto>) {
        switch (mode ? mode.mode : 'list') {
            case 'list':
                return <DataTable<TListDto>{...props} />;

            case 'tree':
                return <DataTree<TListDto & BaseHierarchicalEntity> {...((props as any) as DataTableProps<TListDto & BaseHierarchicalEntity>)} />;
        }
    }

    protected getMode() {
        return this.props.modes?.find(x => x.mode == this.state.viewMode);
    }

    protected buildAddButton(newTitleText: React.ReactNode, props?: any): React.ReactNode | undefined {
        return <Button
            className={'button-add' + (newTitleText ? '' : ' button-empty-text')}
            variant="contained"
            startIcon={<Add />}
            sx={{ margin: 1 }}
            onClick={() => {
                var pars: [string, any?][] | undefined = [[this.props.route?.id || 'id', 'new']];
                this.props.tableProps.routerParams && (pars = this.props.tableProps.routerParams(pars));
                if (this.getMode()?.mode == 'tree') {
                    (pars || (pars = [])).push(['parentId', route.get('id')]);
                }

                pars && route.setState(pars.concat([['hideForm']]));
            }}
            {...props}>
            {newTitleText}
        </Button>;
    }

    private handleColumnFilter() {
        this.setState({ detailedFiter: !this.state.detailedFiter })
    }

    protected buildAnyFilter() {
        if (this.props.hideAnyFilter !== true) {
            return <OutlinedInput
                placeholder="Поиск по любому полю"
                sx={{ flex: 1 }}
                onChange={e => this.setState({ anyFilter: e.target.value })}
                endAdornment={this.buildFilterByColumns() || undefined}
            />;
        }
    }

    protected buildFilterByColumns(): React.ReactNode | undefined | void {
        if (this.props.filterByColumns !== false) {
            return <InputAdornment position="end">
                {document.body.offsetWidth < 1400 ?
                    <IconButton onClick={this.handleColumnFilter}>
                        {this.state.detailedFiter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
                    </IconButton> :
                    <Button color={this.state.detailedFiter ? 'primary' : 'inherit'} onClick={this.handleColumnFilter}>
                        фильтр по столбцам
                    </Button>}
            </InputAdornment>;
        }
    }

    protected buildViewModes(modes?: ViewMode<TListDto>[], viewMode?: string, setViewMode?: ((viewMode: string) => void)) {
        return buildViewModes(modes, viewMode, setViewMode);
    }

    protected buildTitle(): React.ReactNode {
        return <>
            {this.props.toggleMenuOpened && isMobile() ? <IconButton onClick={() => this.props.toggleMenuOpened!()}><MenuRounded /></IconButton> : null}
            <DataBreadcrumbs sx={{ mb: 0 }}>
                <Typography variant="h5">
                    {this.props.titleMultiple || (this.props.title + 'ы')}
                </Typography>
            </DataBreadcrumbs>
        </>;
    }

    protected buildGuide(): React.ReactNode {
        if (this.props.guide) {
            const steps = guides[this.props.guide];
            if (steps) {
                return <><Guide steps={steps} run={!!this.state.guide} onClose={e => this.setState({ guide: false })} />
                    <IconButton color="primary" onClick={e => this.setState({ guide: true })} ><Help /></IconButton>
                </>;
            }
        }
    }

    protected buildEmptyContent() {
        if (this.props.emptyContent) {
            return <Box sx={{ position: 'absolute', zIndex: 1, alignSelf: 'center' }}>{this.props.emptyContent}</Box>;
        }
    }

    protected buildTopToolbar() {
        const newTitleText = this.props.newTitle === undefined ? newTitle(this.props.title) : this.props.newTitle;

        return <Toolbar key="toolbar" sx={{ p: 0 }} disableGutters={true}>
            {this.buildTitle()}
            {this.buildGuide()}
            {this.props.prepend && this.props.prepend()}
            <div style={{ flex: 1 }} />
            {this.props.append && this.props.append()}
            {this.buildAddButton(newTitleText)}
        </Toolbar>
    }

    protected buildFilterBar() {
        return <Box key="toolbar" className='datatable-toolbar' sx={{ gap: 1 }}>
            {this.buildViewModes(this.props.modes, this.state.viewMode, viewMode => this.setState({ viewMode }))}
            {this.buildAnyFilter()}
        </Box>
    }

    protected buildBody() {
        const mobile = isMobile();

        return <>
            {this.buildTopToolbar()}

            {this.props.analyticFilter ? <AnalyticFilter {...this.props.analyticFilter} /> : null}

            <Paper elevation={1} sx={styles.dataView}>
                {this.buildFilterBar()}

                <div key="table" style={{
                    flex: 1,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: mobile ? 'column' : 'row',
                    justifyContent: mobile ? undefined : 'center'
                }}>
                    {this.buildView()}
                    {this.state?.showEmptyContent ? this.buildEmptyContent() : null}
                </div>
            </Paper>
        </>;
    }

    render() {
        const mobile = isMobile();
        const id = getId(this.props);
        if (!mobile || id === undefined || !(id >= 0)) {
            let props = this.props;

            let className = this.props.compact || (this.props.compact === undefined && mobile) ? 'datatable-compact' : '';
            props.hidden && (className += ' datatable-hidden');
            props.className && (className += ' ' + props.className);

            return <Box key="view"
                id={this.id}
                sx={{ ...styles.dataView, ...props.sx, ...(props.hidden ? { display: 'none' } : null) } as SxProps<Theme>}
                data-list-view-typeid={this.props.typeId}
                className={className}>
                {this.buildBody()}                
            </Box>;
        }

        return null;
    }
}

const styles: { [name: string]: SxProps<Theme> } = {
    dataView: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: 2,
        overflow: isMobile() ? 'auto' : 'hidden',
        position: 'relative'
    },
    dataTableWrapper: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
    }
};