import { Add, Check, Close } from '@mui/icons-material';
import { Checkbox, Divider, IconButton, OutlinedInput } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { BaseSelectProps, SelectChangeEvent } from '@mui/material/Select';
import CachedStore from '../../models/CachedStore';
import Proxy from '../../models/Proxy';
import { ISuggest } from '../../models/Types';
import BaseUserComponent from '../base/BaseUserComponent';
import './remotecombo.css';

export interface RemoteComboProps<T> extends BaseSelectProps {
    name?: string
    typeId?: string
    cmd?: string
    value?: number | number[]
    multiple?: boolean
    allowAdd?: boolean
    valueField?: string
    createCmd?: string
    store?: CachedStore
    timeout?: number
    selectFirstOnLoad?: boolean
    nullableText?: React.ReactNode
    filter?: (item: ISuggest<string, number, T>) => boolean
    itemRenderer?: (item: ISuggest<string, number, T>) => React.ReactNode
    onCreate?: (value?: string) => void | Promise<any>
    onDataLoad?: (values?: ISuggest[]) => void
}

interface State {
    key: number
    values?: ISuggest[]
    value?: number | number[]
    create?: boolean
    creatingValue?: string
}

export function configureSuggestStore<T>(cmd: string, valueField: string, timeout?: number) {
    return CachedStore.Configure<ISuggest<string, number, T>>(
        cmd,
        () => Proxy.get(cmd).then(x => {
            const values: ISuggest[] = [];
            if (Array.isArray(x.result.Data)) {
                (x.result.Data as any[]).forEach(x => values.push({ Id: x.Id as number, Value: x[valueField], Obj: x } as ISuggest<string, number, T>));
            } else {
                for (var id in x.result) {
                    values.push({
                        Id: parseInt(id),
                        Value: x.result[id]
                    });
                }
            }

            return values;
        }), timeout);
}

export default class RemoteCombo<T = any> extends BaseUserComponent<RemoteComboProps<T>, State> {
    protected allowAdd: boolean
    protected valueField: string
    protected inputRef: any;

    private store: CachedStore<any>;

    private fieldRenderer?: (item: any) => React.ReactNode
    private menuItemRenderer: (item: ISuggest<string, number, T>) => React.ReactNode

    constructor(p: RemoteComboProps<T>) {
        super(p);

        this.allowAdd = !!p.allowAdd || !!p.createCmd || !!p.onCreate;
        this.valueField = p.valueField || 'value';

        this.state = Object.assign({
            value: this.props.value || (p.multiple ? [] : undefined),
            key: 0
        }, this.state);

        this.onCreate = this.onCreate.bind(this);
        this.onChange = this.onChange.bind(this);

        const itemRenderer = p.itemRenderer || (x => x?.Value);
        if (p.multiple) {
            this.menuItemRenderer = x => <><Checkbox checked={(this.state.value as number[])?.indexOf((x as any).id) > -1} />{itemRenderer(x)}</>
            this.fieldRenderer = x => this.state.values && (x as []).map((id, i) => [
                i ? <br /> : null,
                itemRenderer(this.state.values!.find(x => x.Id == id)!)
            ]);
        } else {
            this.menuItemRenderer = itemRenderer;
        }

        this.store = this.buildStore();
    }

    protected buildStore() {
        return this.props.store ||
            CachedStore.Get<ISuggest>(this.getCmd()) ||
            configureSuggestStore<T>(this.getCmd(), this.valueField, this.props.timeout);
    }

    getCmd() {
        return this.props.cmd || (this.props.typeId + 'Suggest');
    }

    componentDidMount() {
        super.componentDidMount.apply(this);
        if (!this.state.values) {
            this.store.load()?.then(values => {
                this.setState({ values, key: this.state.key + 1 });
                this.props.onDataLoad && this.props.onDataLoad(values);

                if (this.props.selectFirstOnLoad) {
                    this.onChange({
                        target: {
                            name: this.props.name,
                            value: values[0]?.Id
                        }
                    } as any, undefined);
                } else {
                    this.forceUpdate();
                }

                this._unlisten.push(this.store.onLoad((values: any) => {
                    this.setState({ values, key: this.state.key + 1 });
                    this.props.onDataLoad && this.props.onDataLoad(values);
                }));
            });
        }
    }

    componentWillReceiveProps(nextProps: Readonly<RemoteComboProps<T>>, nextContext: any) {
        if (nextProps.value != this.props.value) {
            this.setState({ value: nextProps.value });
        }
    }

    onChange(e: SelectChangeEvent<any>, child: React.ReactNode) {
        const value = Array.isArray(e.target.value) ?
            (e.target.value as []).map(x => parseInt(x)).filter(x => x) :
            (parseInt(e.target.value as string) || undefined);

        this.setState({ value });

        e.target.value = value;
        this.props.onChange && this.props.onChange(e, child);
    }

    onCreate() {
        var v = this.state.creatingValue;
        if (this.props.onCreate) {
            this.props.onCreate(v);
        } else if (this.props.valueField) {
            Proxy.post(this.props.createCmd || (this.props.typeId + 'Save'), {
                [this.props.valueField]: v
            }).then(x => {
                if (x.success) {
                    var nv = {
                        Id: x.result,
                        Value: v || ''
                    };

                    if (this.state.values) {
                        this.state.values.push(nv);
                        this.setState({ value: nv.Id });
                    } else {
                        this.setState({ values: [nv], value: nv.Id });
                    }

                    this.forceUpdate();
                }
            });
        }

        this.setState({ create: false, creatingValue: undefined });
    }

    buildItems() {
        var values = this.state.values;
        if (values) {
            if (this.props.filter) {
                values = values.filter(this.props.filter);
            }

            let items = values.map(x => <MenuItem key={x.Id} value={x.Id.toString()}>{this.menuItemRenderer(x)}</MenuItem>);
            if (!this.props.multiple) {
                if (this.props.nullableText) {
                    items.push(
                        <Divider key="divider" />,
                        <MenuItem key="clear" value="null">{this.props.nullableText}</MenuItem>
                    );
                } else if (this.state.value) {
                    items.push(
                        <Divider key="divider" />,
                        <MenuItem key="clear" value=""><Close /> Очистить</MenuItem>
                    );
                }
            }

            return items;
        }
    }

    render() {
        if (this.state.create) {
            return <div className="remotecombo-wrapper">
                <OutlinedInput key="input" placeholder="Введите новое значение" onChange={e => this.setState({ creatingValue: e.target.value })} />
                <IconButton key="Save" onClick={this.onCreate} color="success"><Check /></IconButton>
                <IconButton key="Close" onClick={() => this.setState({ create: false })} color="error"><Close /></IconButton>
            </div>;
        }

        const select = <Select
            key={'select' + this.state.key}
            inputRef={e => { this.inputRef = e }}
            {...this.props}
            SelectDisplayProps={{ style: { display: 'flex' }, ...this.props.SelectDisplayProps }}
            multiline={true}
            value={this.props.multiple ? (this.state.value as number[])?.map(x => x.toString()) : (this.state.value?.toString() || (this.props.nullableText ? 'null' : ''))}
            renderValue={this.fieldRenderer}
            onChange={this.onChange}>
            {this.buildItems()}
        </Select>;

        if (this.allowAdd) {
            return <div className="remotecombo-wrapper">
                {select}
                <IconButton key="add" onClick={() => this.setState({ create: true })}><Add /></IconButton>
            </div>;
        }

        return select;
    }
}