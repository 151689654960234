import { Circle, EditRounded, NewReleases } from '@mui/icons-material';
import RepeatIcon from '@mui/icons-material/Repeat';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Button, Divider, Paper, SxProps, Theme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import moment from 'moment';
import { useState } from "react";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput, TextInput } from "../../components/form/TextInput";
import { ActivityType, RequestStatus, defaultRequestStatusTitle, requestStatuseMap } from "../../models/Enums";
import Proxy from '../../models/Proxy';
import { Request, RequestActivity } from "../../models/Types";
import { isMobile } from "../../utils";
import HouseCombo from "../houses/HouseCombo";
import InhabitantCombo from "../inhabitants/InhabitantCombo";
import ServiceCombo from "../project/service/ServiceCombo";

export function requestParse(d?: any) {
    const dto = (d || {}) as Request;
    d?.CreateDate && (dto.CreateDate = new Date(d.CreateDate));
    return dto;
}

const activityIcons = {
    [ActivityType.Unknown]: () => <Circle />,
    [ActivityType.Create]: () => <NewReleases />,
    [ActivityType.Comment]: () => <EditRounded />,
    [ActivityType.StatusChange]: () => <RepeatIcon />    
}

function ActivityForm({ entity }: { entity: Request }) {
    const [st, sst] = useState(entity.Status || 0);
    const [com, scom] = useState('');

    function changeStatus(e: any, value: string) {
        const nst = (entity.Status = parseInt(value) as RequestStatus || undefined);
        if (st != nst) {
            Proxy.post('RequestChangeStatus?id=' + entity.Id, nst).then(x => {
                if (x.success) {
                    sst(nst || 0);

                    (entity.Activities || (entity.Activities = [])).push(x.result);
                }
            });
        }
    }

    function addComment() {
        if (com) {
            Proxy.post('RequestAddComment', {
                RequestId: entity.Id,
                Description: com
            } as RequestActivity).then(x => {
                if (x.success) {
                    (entity.Activities || (entity.Activities = [])).push(x.result);

                    scom('');
                }
            });
        }
    }

    return <Paper style={isMobile() ? { flex: 'none' } : { flex: 1 }}>
        <Box>
            <Typography variant="h6">Активность</Typography>

            <ToggleButtonGroup color="primary"
                value={st.toString()}
                size="small"
                exclusive
                onChange={changeStatus}>
                <ToggleButton value="0">{defaultRequestStatusTitle}</ToggleButton>
                {requestStatuseMap.map(x => <ToggleButton value={x[0].toString()}>{x[1]}</ToggleButton>)}
            </ToggleButtonGroup>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Timeline
            sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}>
            {entity.Activities?.map(x => <TimelineItem>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                        {activityIcons[x.Type]()}
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography component="span">{x.Description}</Typography>
                    <Typography>{moment(new Date(x.CreateDate)).calendar()}</Typography>
                </TimelineContent>
            </TimelineItem>)}
        </Timeline>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box>
            <TextInput label="Комментарий" name="Comment" value={com} onChange={e => scom(e.target.value)} inputProps={{ multiline: true }} />

            <Box sx={{ display: 'flex', my: 2 }}>
                <div style={{ flex: 1 }} />
                {<Button variant="contained" onClick={addComment}>Добавить комментарий</Button>}
            </Box>
        </Box>
    </Paper>;
}

export class RequestForm extends AbstractTypedDataForm<Request> {
    protected getTypeId(): string {
        return 'Request';
    }
    protected getTypeName(): React.ReactNode {
        return 'Заявка';
    }

    buildButtons() {
        return [];
    }

    protected createEntity() {
        return requestParse();
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        if (isMobile()) {
            return {
                ...super.getFormStyle(),
                flexDirection: 'column',
                alignItems: 'stretch',
                overflow: 'auto'
            };
        } else {
            return super.getFormStyle();
        }
    }

    protected buildItems(entity?: Request | undefined): React.ReactNode {
        return <>
            <Paper style={isMobile() ? { flex: 'none' } : { minWidth: 500, flex: 'none' }}>
                <FormTextInput label="Наименование" name="Name" form={this} entity={entity} required={true} />

                <TextInput key="ApplicantId"
                    value={entity?.ApplicantId}
                    label="Заявитель"
                    name="ApplicantId"
                    onChange={this.onChange}
                    component={(onChange, error) => <InhabitantCombo
                        name="ApplicantId"
                        error={!!error}
                        value={entity?.ApplicantId}
                        onChange={onChange} />} />

                <TextInput key="ServiceIds"
                    value={entity?.ServiceIds}
                    label="Услуги"
                    name="ServiceIds"
                    onChange={this.onChange}
                    component={(onChange, error) => <ServiceCombo
                        name="ServiceIds"
                        multiple={true}
                        error={!!error}
                        value={entity?.ServiceIds}
                        onChange={onChange} />} />

                <TextInput key="HouseIds"
                    value={entity?.HouseIds}
                    label="Дом(а)"
                    name="HouseIds"
                    onChange={this.onChange}
                    component={(onChange, error) => <HouseCombo
                        name="HouseIds"
                        multiple={true}
                        error={!!error}
                        value={entity?.HouseIds}
                        onChange={onChange} />} />

                <FormTextInput label="Описание" name="Description" inputProps={{ multiline: true }} form={this} entity={entity} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    {<Button variant="contained" type="submit">Сохранить</Button>}
                </Box>
            </Paper>

            {entity?.Id && <ActivityForm entity={entity} />}            
        </>;
    }
}