import RemoteCombo, { RemoteComboProps, configureSuggestStore } from "../../components/form/RemoteCombo";
import { Bill } from "../../models/Types";
import { periodNumToText } from "../../utils";

const cmd = 'BillList';

export const billStore = configureSuggestStore<Bill>(cmd, "Id", 30 * 60);

export default function BillCombo({ name, value, onDataLoad, onChange, allowAdd, multiple, filter, ...props }: RemoteComboProps<Bill>) {
    return <RemoteCombo<Bill>
        name={name}
        store={billStore}
        typeId="Bill"
        allowAdd={allowAdd}
        value={value}
        multiple={multiple}
        onDataLoad={onDataLoad}
        onChange={onChange}
        itemRenderer={x => <>{x.Obj?.Amount}руб.{x.Obj?.PeriodNum ? ' (' + periodNumToText(x.Obj?.PeriodNum) + ')' : null}</>}
        renderValue={(x: any) => <>{x.Obj?.Amount}руб.{x.Obj?.PeriodNum ? ' (' + periodNumToText(x.Obj?.PeriodNum) + ')' : null}</>}
        filter={filter} />;
}