import { Box, Button, Paper, SxProps, Theme } from "@mui/material";
import React from "react";
import { AbstractTypedDataForm } from "../../../components/data/DataForm";
import { FormNumberInput, FormTextInput, TextInput } from "../../../components/form/TextInput";
import { PeriodicService } from "../../../models/Types";
import { isMobile } from "../../../utils";
import PeriodicServiceTypeCombo from "./PeriodicServiceTypeCombo";

export class PeriodicServiceForm extends AbstractTypedDataForm<PeriodicService> {
    protected getTypeName(): React.ReactNode {
        return 'Тариф';
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            ...this.props.sx,
            flexDirection: 'row',
            alignItems: 'flex-start'
        };
    }

    buildButtons() {
        return [];
    }

    protected getTypeId(): string {
        return 'PeriodicService';
    }

    protected buildItems(entity?: PeriodicService | undefined): React.ReactNode {
        return <>
            <Paper sx={isMobile() ? { flex: 1 } : { minWidth: 500 }}>
                <FormTextInput key={`Name-${entity?.Id}`} required={true} label="Наименование" name="Name" form={this} entity={entity} />
                <TextInput key={`Type-${entity?.Id}`}
                    required={true}
                    value={entity?.Type}
                    label="База для расчета"
                    name="Type"
                    onChange={this.onChange}
                    component={(onChange, error) => <PeriodicServiceTypeCombo label="База для расчета" name="Type" value={entity?.Type || 0} onChange={onChange} />} />

                <FormNumberInput key={`Price-${entity?.Id}`} required={true} label="Стоимость за единицу" name="Price" form={this} entity={entity} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <Button variant="contained" type="submit">Сохранить</Button>
                </Box>
            </Paper>
        </>;
    }
}