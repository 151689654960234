import { Add, Delete } from "@mui/icons-material";
import { Button, FormLabel, Grid, IconButton, MenuItem, OutlinedInput, Select, styled } from "@mui/material";
import { ReactNode, useState } from "react";
import { DataGridApiRef } from "../data/ApiRef";

const AddButon = styled(Button)`
    &:not(:hover) {
        text-decoration: underline dashed;
        text-underline-offset: .25em;
    }
`;

export interface DataGridApiProps<T> {
    apiRef?: (api?: DataGridApiRef<T>) => void
}

export interface DataGridProps<T> extends DataGridApiProps<T> {
    label: string
    addText?: string
    entities: T[]
    create: (entity?: T) => T
    row: (entity: T, index: number) => ReactNode
}

export function DataGrid<T>({ entities, label, addText, row, create, apiRef }: DataGridProps<T>) {
    const [key, setKey] = useState(1);
    const [apiSet] = useState({ apiSet: false });

    if (!entities) {
        return <></>;
    }

    function handleAdd() {
        entities.push(create());
        setKey(key + 1);
    }

    function handleDelete(c: T) {
        entities.splice(entities.indexOf(c), 1);
        setKey(key + 1);
    }

    if (apiRef && !apiSet.apiSet) {
        apiSet.apiSet = true;
        apiRef({
            add: t => {
                entities.push(create(t));
                setKey(key + 1);
            }
        });
    }

    return <>
        <Grid item xs={12} sx={{ display: 'flex' }}>
            {label && <FormLabel>{label}</FormLabel>}
            <div style={{ flex: 1 }} />
            <AddButon color="success" variant="text" startIcon={<Add />} size="small" onClick={handleAdd}>
                Добавить {addText || ''}
            </AddButon>
        </Grid>
        <div key={'contactsTable' + key} className="contacts">
            {entities.map((c, i) => <div key={i}>
                {row(c, i)}
                <IconButton onClick={() => handleDelete(c)}><Delete /></IconButton>
            </div>)}
        </div>
    </>
}