import RemoteCombo, { RemoteComboProps, configureSuggestStore } from "../../components/form/RemoteCombo";
import { Person } from "../../models/Types";

const cmd = 'InhabitantSuggest';
const valueField = 'Name';

export const inhabitantStore = configureSuggestStore<Person>(cmd, valueField, 30 * 60);

export default function InhabitantCombo({ name, value, onDataLoad, onChange, multiple }: RemoteComboProps<Location>) {
    return <RemoteCombo<Person>
        name={name}
        store={inhabitantStore}
        typeId="Person"
        value={value}
        multiple={multiple}
        onDataLoad={onDataLoad}
        onChange={onChange} />;
}