import { ColumnData } from '../../../components/data/DataTable';
import DataView from '../../../components/data/DataView';
import { Tariff } from '../../../models/Types';
import { TariffForm, tariffParse } from './TariffForm';

const columns: ColumnData<Tariff>[] = [{
    label: 'Наименование',
    name: 'Name',
    filter: true,
    routerLink: true
}, {
    label: 'Стоимость',
    name: 'Price',
    type: 'numeric',
    filter: true
}, {
    label: 'Норма',
    name: 'Norm',
    type: 'numeric',
    filter: true
}, {
    label: 'Ед.измерения',
    name: 'Unit',
    filter: true
}, {
    label: 'Дата начала',
    name: 'StartDate',
    type: 'date',
    filter: true
}];

export default function TariffView() {
    return <DataView<Tariff>
        title="Тариф"
        titleMultiple="Тарифы"
        newTitle="Новый тариф"
        typeId="Tariff"
        form={(id, apiRef) => <TariffForm id={id} apiRef={apiRef} />}
        tableProps={{
            columns: columns,
            dataParser: tariffParse
        }} />;
}