import moment from 'moment';
import Route from './Router'

Route.setBaseUrl(window.location.origin + '/');

Route.register('page', () => 'overview');
Route.register('subpage', () => '');
Route.register('id', undefined, undefined, x => parseInt(x)?.toString() == x ? parseInt(x) : x);
Route.register('tab');
Route.register('copy', undefined, x => x ? 'true' : '', x => x ? x.toLowerCase() === 'true' ? true : false : undefined);

Route.addRoute('')
    .addUrlPath('page')
    .addUrlPath('id')
    .addUrlPath('tab');

Route.hashChanged();

//
moment.locale('ru');