import { Chip, Paper, Stack, SxProps, Theme } from "@mui/material";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { Post } from "../../models/Types";
import { isMobile } from "../../utils";

export class PostCard<TProps = {}, TState = {}> extends AbstractTypedDataForm<Post, TProps, TState> {

    protected getTypeId(): string {
        return 'Post';
    }

    protected getTypeName(): React.ReactNode {
        return 'Новость';
    }

    buildButtons() {
        return [];
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            ...super.getFormStyle(),
            alignItems: isMobile() ? 'stretch' : 'flex-start',
            flexDirection: 'column'
        };
    }

    protected buildItems(entity?: Post | undefined): React.ReactNode {
        if (!entity) {
            return <></>;
        }

        return <>
            <Stack direction="row" spacing={1} mt={2}>
                {entity.HashTags?.map(x => <Chip label={x} variant="outlined" />)}
            </Stack>

            <Paper style={isMobile() ? { flex: 'none' } : { minWidth: 500, minHeight: 350, flex: 'none' }} dangerouslySetInnerHTML={{ __html: entity.Body }} />
        </>;
    }
}