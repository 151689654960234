import { Addchart, CarRentalRounded, ElectricMeter, Feedback, Home, HomeRepairServiceRounded, LocationOn, NewspaperRounded, NightShelter, PointOfSaleRounded, Receipt, Speed } from "@mui/icons-material";
import { Step } from "react-joyride";
import { UrlRoute } from "../Router";
import Guide, { guides } from "../guides/Guide";
import Logo from "../icons/Logo";
import { useRoute } from "../models/Hooks";
import { isOwner } from "../models/Identity";
import BillView from "../screens/bill";
import Dashboard from "../screens/dashboard";
import EntryPassView from "../screens/entrypass";
import HouseView from "../screens/houses";
import InhabitantView from "../screens/inhabitants";
import LocationView from "../screens/locations";
import MeasureView from "../screens/measure";
import MeterView from "../screens/meter";
import PaymentView from "../screens/payment";
import PostView from "../screens/post";
import ProfileView from "../screens/profile";
import ProjectView from "../screens/project";
import ProvidedServiceView from "../screens/providedService";
import RequestView from "../screens/request";

const projectManageMenu: Step[] = [
    {
        target: '#project-manage-menu',
        title: 'Управление ЖК',
        content: <>В меню <b>Управление ЖК</b> собраны основные разделы системы, необходимые для повседневное работы</>,
    },
    {
        target: '#emploees-menu',
        title: 'Сотрудники',
        content: <><b>Сотрудники</b> Проекта могут
            <ul>
                <li>Брать в работу заявки на услуги</li>
                <li>Администрировать Проект</li>
                <li>Для каждого <b>Сотрудника</b> может быть создан отдельный пользовательский аккаунт</li>
            </ul>
        </>,
    },
    {
        target: '#project-menu-currency',
        title: 'Услуги',
        content: <>В разделе <b>Услуги</b> можно настроить стоимость предоставляемых услуг.
            <p>При создании нового Проекта в нем по умолчанию уже есть услуга <b>Тех обслуживание</b> стоимостью <b>1000 рублей</b></p>
        </>,
    },
    {
        target: '#locations-menu',
        title: 'Локации',
        content: <>
            В разделе <b>Локации</b> можно посмотреть дома, камеры, проходные или любые геолокационные точки.
            <p>В <b>Заявках</b> можно указывать <b>Локации</b> для любой заявки</p>
        </>,
    }
].map(x => {
    return {
        ...x,
        placementBeacon: 'right',
        placement: 'right'
    }
});
guides.projectManageMenu = projectManageMenu;

export interface IAppMenuItem {
    id?: string
    path?: string,
    title?: React.ReactNode,
    tenanted?: boolean,
    icon?: React.ReactNode,
    element?: () => React.ReactNode,
    color?: string
}
export interface IAppMenuNode extends IAppMenuItem {
    children?: (IAppMenuNode | null)[]
}

export type IAppMenu = (IAppMenuItem | null)[];

export const hierarchicalMenu: (IAppMenuNode | null)[] = [{
    title: 'Обзор',
    children: [{
        id: 'desktop-menu',
        path: "",
        title: 'Аналитика',
        icon: <Speed />,
        element: () => <Dashboard />,
    },
    {
        id: 'measure-menu',
        path: "measure/*",
        title: 'Показания',
        icon: <Addchart />,
        element: () => <MeasureView />,
    },
    {
        id: 'request-menu',
        path: "request/*",
        title: 'Заявки',
        icon: <Feedback />,
        element: () => <RequestView />,
    },
    {
        id: 'providedService-menu',
        path: "service/*",
        title: 'Услуги',
        icon: <HomeRepairServiceRounded />,
        element: () => <ProvidedServiceView />,
    },
    {
        id: 'bill-menu',
        path: "bill/*",
        title: 'Начисления',
        icon: <Receipt />,
        element: () => <BillView />,
    },
    {
        id: 'payment-menu',
        path: "payment/*",
        title: 'Платежи',
        icon: <PointOfSaleRounded />,
        element: () => <PaymentView />,
    },
    {
        id: 'entrypass-menu',
        path: "entrypass/*",
        icon: <CarRentalRounded />,
        title: 'Пропуска',
        element: () => <EntryPassView />,
    }]
}, {
    id: 'project-manage-menu',
    title: <>{isOwner() ? 'Управление ' : null}ЖК<Guide guide="projectManageMenu" /></>,
    children: [/**
    {
        id: 'clients-menu',
        path: "clients/*",
        title: 'Клиенты',
        tenanted: true,
        icon: <Mood />,
        element: () => <Clients />,
    },
    {
        id: 'finance-menu',
        path: "finance",
        title: 'Расчеты',
        tenanted: true,
        icon: <CreditCard />,
        element: () => <Finance />,
    },/**
    {
        id: 'emploees-menu',
        path: "emploees/*",
        icon: <Badge />,
        tenanted: true,
        title: 'Сотрудники',
        element: () => <Employees />,
    },
    {
        id: 'project-menu-currency',
        path: "currency",
        title: 'Услуги',
        tenanted: true,
        icon: <LeakAdd />,
        element: () => <CurrencyView />,
    },/**/
        {
            id: 'house-menu',
            path: "house/*",
            icon: <Home />,
            title: 'Домовладения',
            element: () => <HouseView />,
        },
        {
            tenanted: true,
            id: 'inhabitant-menu',
            path: "inhabitant/*",
            icon: <NightShelter />,
            title: 'Жильцы',
            element: () => <InhabitantView />,
        },
        {
            id: 'meter-menu',
            path: "meter/*",
            icon: <ElectricMeter />,
            title: 'Счётчики',
            element: () => <MeterView />,
        },
        {
            id: 'news-menu',
            path: "news/*",
            title: 'Новости',
            icon: <NewspaperRounded />,
            element: () => <PostView />,
        }/**/,
        {
            id: 'locations-menu',
            path: "locations/*",
            icon: <LocationOn />,
            title: 'Локации',
            element: () => <LocationView />,
        }/**/]
},
    null,
{
    tenanted: true,
    path: "project/*",
    icon: <Logo width={24} />,
    title: 'О ЖК',
    color: 'primary.main',
    element: () => <ProjectView />,
},
{
    path: "project/:id",
    element: () => <ProjectView />,
},
{
    path: "profile/:id",
    element: () => <ProfileView />,
}];

export const menu: IAppMenu = hierarchicalMenu.reduce((a, b) => b?.children ? [...a, b, ...b.children] : [...a, b], [] as IAppMenu);

export const route = menu.filter(x => x);

export function useAppRoute(menu: IAppMenu, onChange?: (path: string) => any): [UrlRoute, string, number, IAppMenuItem] {
    const [route, path] = useRoute(onChange);
    var rawMenu = (menu as IAppMenuNode[])
        .reduce((a, b) => b?.children ? [...a, b, ...b.children] : [...a, b], [] as IAppMenu)
        .reverse();

    var current = rawMenu.findIndex(x => (x?.path || x?.path === '') && path.startsWith(x.path.replace(/\/\*$/, '').replace(/\/:.*$/, '')));
    return [route, path, current, rawMenu[current]!];
}