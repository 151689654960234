import { Box, Button, IconButton, Link, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode, useState } from 'react';
import route from '../../Router';
import { useRoute, useTenantId } from '../../models/Hooks';
import { ListenerDestructor } from '../../models/Observer';
import { IEntity } from '../../models/Types';
import { isMobile } from '../../utils';
import TabPanel, { Disabled, SetTabApi } from '../TabPanel';
import { FormApiRef, ListApiRef } from './ApiRef';
import { DataBreadcrumbs } from "./DataBreadcrumbs";
import DataListView, { ListViewProps, getId } from './DataListView';
import './dataview.css';
import AppDrawer from "../../nav/AppDrawer";
import { MenuRounded } from "@mui/icons-material";

type FormElement = (id: number, apiRef: (api?: FormApiRef) => void, params?: any, setTabApi?: SetTabApi) => React.ReactNode | IFormInfo[] | undefined;

interface IFormInfo {
    title: string
    icon?: React.ReactNode
    disabled?: Disabled;
    element: FormElement
}

export interface ViewProps<TistDto extends IEntity> extends ListViewProps<TistDto> {
    form: IFormInfo[] | FormElement;
    editForm?: IFormInfo[] | FormElement;
    formTitle?: (entity: IEntity) => ReactNode
    canEdit?:boolean
    hideFormAfterSave?: boolean
    hideFormRowSelector?: boolean;
    listView?: (props: ViewProps<TistDto>, apiRef?: (api?: ListApiRef<TistDto>) => void) => React.ReactNode
    viewSx?: SxProps<Theme>
    formSx?: SxProps<Theme>
}

export default function DataView<TistDto extends IEntity>(props: ViewProps<TistDto>) {
    useRoute();

    var [listApi, setListApi] = useState<(ListApiRef<TistDto> | undefined)>();
    var [formApi, setFormApi] = useState<(FormApiRef | undefined)>();
    const [formApiDestructors, setApiDestructors] = useState<ListenerDestructor[]>();
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [title, setTitle] = useState<string>();

    const tenantId = useTenantId();

    function apiRef(fa?: FormApiRef, la?: ListApiRef<TistDto>) {
        if (!fa) {
            fa = formApi;
        }

        if (fa) {
            if (!la) {
                la = listApi;
            }

            formApiDestructors?.forEach(x => x());
            setApiDestructors([
                fa.onSave(id => {
                    la?.update && la.update(id);
                    const state: [string, any?][] = [[props.route?.id || 'id', id], ['copy']];
                    props.hideFormAfterSave !== false && state.push(['hideForm', true]);
                    route.setState(state);
                }),
                fa.onDelete(id => {
                    la?.delete && la.delete(id);
                    const state: [string, any?][] = [[props.route?.id || 'id'], ['tab']];
                    route.setState(state);
                }),
                fa.onChange((f, n, o, e) => la?.change && la.change(e.id, f, n))
            ]);
        }
    }

    function formApiRef(api?: FormApiRef) {
        api?.onLoad(r => setTitle(props.formTitle ? props.formTitle(r) : r.Name));
        api?.onSave(r => setEdit(false));

        formApi = api;
        setFormApi(api);
        apiRef(api);
    }

    function listApiRef(api?: ListApiRef<TistDto>) {
        listApi = api;
        setListApi(api);
        apiRef(undefined, api);
    }

    function buildForm() {
        if (route.get('hideForm')) {
            return null;
        }

        const id = getId(props);
        if (id === undefined) {
            return null;
        }

        let params: any = {};
        var formInfos: IFormInfo[];

        const formInfo = (edit || !parseInt(id) ? props.editForm : props.form) || props.form;
        if (Array.isArray(formInfo)) {
            formInfos = formInfo as IFormInfo[];
        } else {
            let result = (formInfo as FormElement)(id, formApiRef, params);
            if (result !== undefined && Array.isArray(result)) {
                formInfos = result as IFormInfo[];
            } else {
                return result;
            }
        }

        return <TabPanel
            route={{ param: 'tab' }}
            style={{ height: '100%' }}
            isNew={!id}
            tabs={formInfos.map(x => {
                return {
                    title: x.title,
                    icon: x.icon,
                    disabled: x.disabled,
                    element: setTabApi => x.element(id, formApiRef, params, setTabApi) as React.ReactNode
                };
            })} />;
    }

    const form = buildForm();

    var viewSx = props.viewSx ? { ...styles.box, ...props.viewSx } as SxProps<Theme> : styles.box;

    const ism = isMobile();

    var box = <Box key={tenantId} sx={viewSx} className={'dataview' + (form ? ' dataview-form' : '')}>
        {props.listView ?
            props.listView(props, listApiRef) :
            <DataListView<TistDto> {...props} apiRef={listApiRef} hidden={!!form} toggleMenuOpened={() => setOpen(!open)} />}

        {form && <Box key="form" sx={isMobile() ? styles.formMobile : styles.formDesktop} className="dataview-form-wrapper">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {ism ? <IconButton onClick={() => setOpen(!open)}><MenuRounded /></IconButton> : null}
                <DataBreadcrumbs separator="•" sx={ism ? { mb: 0 } : null}>
                    {form ? [
                        <Link underline="hover" color="inherit" variant="h5" data-route="id=">
                            {props.titleMultiple || (props.title + 'ы')}
                        </Link>,
                        <Typography variant="h5">
                            {route.get('id') > 0 ? ((edit || !props.editForm) ? null : title) || 'Редактирование' : 'Добавление'}
                        </Typography>
                    ] :
                        <Typography variant="h5">
                            {props.titleMultiple || (props.title + 'ы')}
                        </Typography>}
                </DataBreadcrumbs>
                {props.editForm && props.canEdit !== false ?
                    edit ?
                        <Button variant="contained" sx={{ ml: 'auto' }} onClick={() => setEdit(false)}>Отменить изменения</Button> :
                        <Button variant="contained" sx={{ ml: 'auto' }} onClick={() => setEdit(true)}>Редактировать</Button> :
                    null}
            </Box>
            {form}
        </Box>}
    </Box>;

    if (ism) {
        return <>
            {box}
            <AppDrawer key={'drawer' + open} open={open} />
        </>;
    }

    return box;
}

export function Form({ children }: any) {
    return <Box sx={styles.form}>
        {children}
    </Box>;
}

const styles: { [name: string]: SxProps<Theme> } = {
    box: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        overflow: "hidden"
    },
    form: {
        marginTop: 3,
        padding: 2,
        width: 350,
        alignSelf: 'start',
        borderTopLeftRadius: '.5rem',
        borderBottomLeftRadius: '.5rem',
        backgroundColor: 'secondary.main'
    },
    formMobile: {
        width: '100%',
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    formDesktop: {
        maxWidth: '100%',
        //width: '36rem',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    }
}