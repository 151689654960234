import { Autocomplete, Box, Button, Paper, TextField } from "@mui/material";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput } from "../../components/form/TextInput";
import { Post } from "../../models/Types";
import { isMobile } from "../../utils";
import Proxy from "../../models/Proxy";

const w = window as any;

export function postParse(d?: any) {
    const dto = (d || {}) as Post;
	d?.CreateDate && (dto.CreateDate = new Date(d.CreateDate));
	dto.HashTags || (dto.HashTags = []);
    return dto;
}

export class PostForm extends AbstractTypedDataForm<Post, {}, { tags: string[] }> {
	private editor: any;

	componentDidMount() {
		super.componentDidMount();

		Proxy.get('PostTags').then(x => this.setState({ tags: x.result }));

		if (!w.CKEDITOR) {
			const script = document.createElement("script");
			script.src = "https://cdn.ckeditor.com/ckeditor5/43.3.1/ckeditor5.umd.js";
			script.async = true;
			script.onload = () => this.forceUpdate();

			document.body.appendChild(script);
		}
	}

    protected getTypeId(): string {
        return 'Post';
	}

    protected getTypeName(): React.ReactNode {
        return 'Новость';
    }

    buildButtons() {
        return [];
    }

    protected createEntity() {
        return postParse();
	}

	onEditorRef(e: any, entity?: Post | undefined) {
		this.editor?.destroy();


		if (!e || !w.CKEDITOR) { 
			return;
		}

		const {
			AccessibilityHelp,
			AutoImage,
			Autoformat,
			Autosave,
			BlockQuote,
			Bold,
			CKBox,
			CKBoxImageEdit,
			ClassicEditor,
			CloudServices,
			Essentials,
			Heading,
			ImageBlock,
			ImageCaption,
			ImageInline,
			ImageInsert,
			ImageInsertViaUrl,
			ImageResize,
			ImageStyle,
			ImageTextAlternative,
			ImageToolbar,
			ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			LinkImage,
			List,
			ListProperties,
			MediaEmbed,
			Paragraph,
			PasteFromOffice,
			PictureEditing,
			SelectAll,
			Table,
			TableCaption,
			TableCellProperties,
			TableColumnResize,
			TableProperties,
			TableToolbar,
			TextTransformation,
			TodoList,
			Underline,
			Undo
		} = (w.CKEDITOR);

		ClassicEditor
			.create(e, {
				plugins: [
					AccessibilityHelp,
					Autoformat,
					AutoImage,
					Autosave,
					BlockQuote,
					Bold,
					CKBox,
					CKBoxImageEdit,
					CloudServices,
					Essentials,
					Heading,
					ImageBlock,
					ImageCaption,
					ImageInline,
					ImageInsert,
					ImageInsertViaUrl,
					ImageResize,
					ImageStyle,
					ImageTextAlternative,
					ImageToolbar,
					ImageUpload,
					Indent,
					IndentBlock,
					Italic,
					Link,
					LinkImage,
					List,
					ListProperties,
					MediaEmbed,
					Paragraph,
					PasteFromOffice,
					PictureEditing,
					SelectAll,
					Table,
					TableCaption,
					TableCellProperties,
					TableColumnResize,
					TableProperties,
					TableToolbar,
					TextTransformation,
					TodoList,
					Underline,
					Undo
				],
				translations: ['ru'],
				toolbar: {
					items: [
						'undo',
						'redo',
						'|',
						'heading',
						'|',
						'bold',
						'italic',
						'underline',
						'|',
						'link',
						'insertImage',
						'ckbox',
						'mediaEmbed',
						'insertTable',
						'blockQuote',
						'|',
						'bulletedList',
						'numberedList',
						'todoList',
						'outdent',
						'indent'
					],
					shouldNotGroupWhenFull: false
				},
				heading: {
					options: [
						{
							model: 'paragraph',
							title: 'Paragraph',
							class: 'ck-heading_paragraph'
						},
						{
							model: 'heading1',
							view: 'h1',
							title: 'Heading 1',
							class: 'ck-heading_heading1'
						},
						{
							model: 'heading2',
							view: 'h2',
							title: 'Heading 2',
							class: 'ck-heading_heading2'
						},
						{
							model: 'heading3',
							view: 'h3',
							title: 'Heading 3',
							class: 'ck-heading_heading3'
						},
						{
							model: 'heading4',
							view: 'h4',
							title: 'Heading 4',
							class: 'ck-heading_heading4'
						},
						{
							model: 'heading5',
							view: 'h5',
							title: 'Heading 5',
							class: 'ck-heading_heading5'
						},
						{
							model: 'heading6',
							view: 'h6',
							title: 'Heading 6',
							class: 'ck-heading_heading6'
						}
					]
				},
				image: {
					toolbar: [
						'toggleImageCaption',
						'imageTextAlternative',
						'|',
						'imageStyle:inline',
						'imageStyle:wrapText',
						'imageStyle:breakText',
						'|',
						'resizeImage',
						'|',
						'ckboxImageEdit'
					]
				},
				initialData: entity?.Body,
				autosave: {
					save: (editor: any) => {
						this.onChange({
							target: {
								name: 'Body',
								value: editor.getData()
							}
						});
					}
				},
			}).then((x: any) => {
				this.editor = x;
			});
	}

    protected buildItems(entity?: Post | undefined): React.ReactNode {
        return <>
			<Paper sx={isMobile() ? { flex: 1 } : { minWidth: 500, minHeight: 350 }}>
				<FormTextInput label="Заголовок" name="Name" form={this} entity={entity} />

				<Autocomplete multiple
					options={this.state?.tags || []} defaultValue={entity?.HashTags} freeSolo
					renderInput={(params) => <TextField {...params} variant="outlined" label="Тэги" name="HashTags" />}
					onChange={(e: any, v) => this.onChange({
						target: {
							name: 'HashTags',
							value: v
						}
					})}
				/>

				<div key="cke" ref={e => this.onEditorRef(e, entity)} />

				<Box sx={{ display: 'flex' }}>
					{this.buildDeleteButton()}
                    <div style={{ flex: 1 }} />
                    {<Button variant="contained" type="submit">Сохранить</Button>}
                </Box>
            </Paper>
        </>;
    }
}