import { Button, ButtonProps, IconButton, IconButtonProps, Link, LinkProps } from "@mui/material";
import { NavLinkComponent, NavLinkProps } from "../Router";

export default function NavLink(props: LinkProps & NavLinkProps) {
    return <Link component={NavLinkComponent} {...props} />;
}

export function NavButton(props: ButtonProps & NavLinkProps) {
    return <Button component={NavLinkComponent} {...props} />;
}

export function NavIconButton(props: IconButtonProps & NavLinkProps) {
    return <IconButton component={NavLinkComponent} {...props} />;
}