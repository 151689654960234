import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import DataListView from '../../../components/data/DataListView';
import { ColumnData } from '../../../components/data/DataTable';
import DataView from '../../../components/data/DataView';
import { guides } from '../../../guides/Guide';
import Proxy from '../../../models/Proxy';
import { Account } from '../../../models/Types';
import { AccountForm } from './AccountForm';

guides.accountGuide = [{
    target: '[data-list-view-typeid="Account"] .datatable-toolbar',
    disableBeacon: true,
    title: 'Баланс пользователя',
    content: <>Информация о балансе в конкретном или во всех ЖК, в которых Вы состоите</>
}, {
    target: '[data-name="currency"]',
    title: 'Баланс. Услуги ЖК',
    placementBeacon: 'right-start',
    content: <>
        В столбце <b>Наименование</b> отображаются названия услуги Проекта.
        <p>Например, <i>Индивидуальное</i> или <i>Групповое</i> услуги.</p>
        <p>Также всегда есть строка <i>Рубль</i> с денежным балансом в рублях.</p>
    </>
}, {
    target: '[data-name="currencyId"]',
    title: 'Баланс. Пополнение',
    placementBeacon: 'right-start',
    content: <>
    </>
}, {
    target: '#account-all-tenants',
    title: 'Баланс по ЖК',
    placementBeacon: 'right-start',
    content: <>Для отображения баланса по всем ЖК, необходимо поставить галочку <b>Все ЖК</b></>
}];

export const accountColumns: ColumnData<Account>[] = [{
    label: 'Наименование',
    name: 'Currency',
    filter: true,
    renderer: v => v || 'Рубль'
}, {
    label: 'Количество',
    name: 'Amount',
    filter: true
}, {
    label: 'Пополнение',
    name: 'CurrencyId',
    //store: tariffStore,
    sx: () => {
        return {
            textAlign: 'center'
        }
    },
    renderer: (v, obj, theme, table) => <Button variant="outlined" sx={{ my: .5 }}>
        {v ? 'Купить (' + obj.MainCurrencyRate + '₽)' : 'Пололнить'}
    </Button>
}, {
    label: 'Ближайшее списание',
    name: 'CurrencyId',
    renderer: v => ''
}];

export class AccountListView extends DataListView<Account>{
    buildAddButton() {
        return <FormControlLabel id="account-all-tenants" control={<Checkbox />} label="Все ЖК" />;
    }
}

export default function AccountView() {
    return <DataView<Account>
        title="Баланс"
        titleMultiple="Баланс"
        typeId="Account"
        listView={(props, setListApi) => <AccountListView {...props} apiRef={setListApi} />}
        form={[{
            title: 'Локация',
            element: (id, apiRef, params) => <AccountForm id={id} apiRef={apiRef} />
        }]}
        guide="accountGuide"
        newTitle=''
        tableProps={{
            columns: accountColumns
        }} />;
}