import { CheckBoxOutlined, SquareOutlined } from "@mui/icons-material";
import { SxProps, Theme, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";
import { useState } from 'react';

interface TriStateCheckboxProps {
    root?: ToggleButtonGroupProps,
    name?: string
    value?: boolean
    text?: [string | undefined, string | undefined]
    activeColor?: [any, any]
    color?: [any, any]
    icons?: [any, any]
    sx?: SxProps<Theme>
    onChange?: (e: { target: { name?: string, value?: boolean } }) => void
}

export default function TriStateCheckbox(props: TriStateCheckboxProps) {
    const [v, sv] = useState(props.value);

    return <ToggleButtonGroup exclusive
        {...props.root}
        sx={props.sx}
        value={v === true ? '1' : v === false ? '0' : null}
        onChange={(e, v) => {
            const value = v == '1' ? true : v == '0' ? false : undefined;
            sv(value);
            props.onChange && props.onChange({ target: { name: props.name, value } });
        }}>
        <ToggleButton value="1" color={(v && props.activeColor && props.activeColor[0]) || (props.color && props.color[0])}>
            {props.icons && props.icons[0] || <CheckBoxOutlined />}
            {props.text && props.text[0]}
        </ToggleButton>
        <ToggleButton value="0" color={(v === false && props.activeColor && props.activeColor[1]) || (props.color && props.color[1])}>
            {props.icons && props.icons[1] || <SquareOutlined />}
            {props.text && props.text[1]}
        </ToggleButton>
    </ToggleButtonGroup>
}