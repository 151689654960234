import { ColumnData } from '../../../components/data/DataTable';
import DataView from '../../../components/data/DataView';
import { Service } from '../../../models/Types';
import { ServiceForm } from './ServiceForm';

const columns: ColumnData<Service>[] = [{
    label: 'Наименование',
    name: 'Name',
    filter: true,
    routerLink: true
}, {
    label: 'Стоимость',
    name: 'Price',
    type: 'numeric',
    filter: true
}, {
    label: 'Ед.измерения',
    name: 'Unit',
    filter: true
}];

export default function ServiceView() {
    return <DataView<Service>
        title="Услуга"
        titleMultiple="Услуги"
        typeId="Service"
        form={(id, apiRef) => <ServiceForm id={id} apiRef={apiRef} />}
        tableProps={{
            columns: columns
        }} />;
}