import { Card, CardActions, CardContent, CardHeader, ImageList, ImageListItem, Typography } from '@mui/material';
import moment from 'moment';
import route from '../../Router';
import DataListView, { getId } from '../../components/data/DataListView';
import { BaseDataTable, ColumnData, DataTableProps } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import { ViewMode } from '../../components/data/ViewModes';
import { isOwner } from '../../models/Identity';
import { Post } from '../../models/Types';
import { theme } from '../../theme';
import { PostCard } from './PostCard';
import { PostForm, postParse } from './PostForm';

const columns: ColumnData<Post>[] = [{
    label: 'Заголовок',
    name: 'Name',
    filter: true,
    routerLink: true
}, {
    label: 'Тэги',
    name: 'HashTags',
    type: 'array',
    filter: true,
    renderer: (val, obj) => (val as string[])?.join(', ')
}, {
    label: 'Дата публикации',
    name: 'Body',
    type: 'date',
    filter: true
}];

class PostTable extends BaseDataTable<Post>{

    public getData() {
        return super.getData()?.sort((a, b) => b.CreateDate.getTime() - a.CreateDate.getTime());
    }

    renderTable() {
        return <ImageList variant="masonry" cols={document.body.offsetWidth > 1280 ? 3 : (document.body.offsetWidth > 820 ? 2:1)} gap={8}>
            {this.getData().map((row, i) => <ImageListItem key={i} component={Card} sx={{ cursor: 'pointer' }} data-route={'hideForm=&id=' + row.Id}>
                {row.Name ? <CardHeader title={row.Name} /> : null}
                <CardContent>
                    <div dangerouslySetInnerHTML={{ __html: row.Body }} />
                </CardContent>
                <CardActions disableSpacing>
                    <Typography variant="caption">{moment(row.CreateDate).calendar()}</Typography>
                </CardActions>
            </ImageListItem>)}
        </ImageList>;
    }
}

export class PostListView extends DataListView<Post>{
    protected renderTable(props: DataTableProps<Post>, mode?: ViewMode<Post>) {
        return <PostTable {...props} theme={theme} />;
    }

    buildAddButton(newTitleText: React.ReactNode, props?: any) {
        return super.buildAddButton('Опубликовать', props);
    }

    buildBody() {
        return <>
            {this.buildTopToolbar()}

            {this.buildView()}
        </>;
    }

    render() {
        if (getId(this.props) >= 0 && !route.get('hideForm')) {
            return <></>;
        } else {
            return super.render();
        }
    }
}

export default function PostView() {
    return <DataView<Post>
        title="Новость"
        titleMultiple="Новости"
        typeId="Post"
        form={(id, apiRef) => <PostCard key="main" id={id} apiRef={apiRef} />}
        editForm={(id, apiRef) => <PostForm key="main" id={id} apiRef={apiRef} />}
        canEdit={isOwner()}
        listView={(props, setListApi) => <PostListView {...props} apiRef={setListApi} /> }
        tableProps={{ columns, dataParser: postParse }} />;
}