import { ColumnData } from "../../components/data/DataTable";
import { personListColumns, PersonListDto } from "../../components/person/PersonList";
import HouseCombo, { houseStore } from "../houses/HouseCombo";

export const inhabitantListColumns: ColumnData<PersonListDto>[] = personListColumns.filter(x => x.name !== 'CreateDate');
inhabitantListColumns.splice(2, 0, {
    width: 120,
    label: 'Дом(а)',
    name: 'HouseIds',
    type: 'array',
    store: houseStore,
    filter: (onChange) => <HouseCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => (val as number[])?.map(v => houseStore.data?.filter(x => x.Id == v).map(x => x.Value)).join(', ')
});