import { Box, Button, ButtonProps, LinearProgress, SxProps, Theme, styled } from "@mui/material";
import React, { ReactNode } from "react";
import route from "../../Router";
import Proxy from "../../models/Proxy";
import { IEntity } from "../../models/Types";
import { genId, isMobile } from "../../utils";
import { OnDirtyHandler, SetTabApi } from "../TabPanel";
import { LoadingForm } from "../base/LoadingForm";
import { FormApiRef } from "./ApiRef";
import DeleteDialog, { DeleteDialogProps } from "./DeleteDialog";

interface DataFormProps<T> {
    apiRef?: (api?: FormApiRef) => void
    copy?: boolean
    sx?: SxProps<Theme>
    setTabApi?: SetTabApi
    delete?: DeleteDialogProps
}

interface TypeDataFormProps {
    typeId: string;
}

interface DataFormState<T> {
    entity: T,
    callbackWidget?: React.ReactNode
}

const StyledFormBox = styled('form')(
    ({ theme }) => ({
        gap: '1rem',
        '& .MuiPaper-root': {
            padding: '1rem 1.5rem',
            display: 'flex',
            gap: '1rem',
            overflow: 'hidden',
            flexDirection: 'column',
            flex: 1
        }
    }),
);

export abstract class AbstractDataForm<T extends IEntity, TProps = {}, TState = {}> extends LoadingForm<TProps & DataFormProps<T>, TState & DataFormState<T>> {
    protected responseEntityProp?: string;

    protected _changed?: boolean;
    protected dirtyHandler?: OnDirtyHandler;

    protected validators: {
        [name: string]: {
            fieldName: keyof T,
            validator: (v?: any) => string | undefined
        }
    };

    constructor(p: any) {
        super(p);

        this.validators = {};

        this.save = this.save.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);

        p.setTabApi && this.setTabApi(p.setTabApi);
    }

    componentWillReceiveProps(nextProps: any, nextContext: any): void {
        super.componentWillReceiveProps(nextProps, nextContext);
        if (this.props.id == nextProps?.id && this.state?.entity && !!nextProps?.copy != !this.state?.entity?.Id) {
            this.state.entity.Id = nextProps?.copy ? 0 : this.props.id;
            this.forceUpdate();
        }
    }

    componentDidMount(): void {
        this.applyRefApi();

        this.unlisten.push(route.before((r, p) => {
            if (!this._changed ||
                (r.getParameterByName<number | undefined>('id', true, p) == this.props.id &&
                    r.getParameterByName<string | undefined>('hideForm', true, p) == r.get('hideForm'))) {
                return Promise.resolve(true);
            }

            let res = window.confirm('Имеются несохраненные данные в форме редактирования. Отменить изменения?');
            if (res) {
                this.observer.fire('reset', [this.props.id]);
            }
            return Promise.resolve(res);
        }));

        super.componentDidMount();
    }


    clear(): void {
        this.setState({
            id: 0,
            entity: this.createEntity()
        });
    }

    private applyRefApi() {
        this.props.apiRef && this.props.apiRef({
            onSave: l => this.observer.listen('save', l),
            onDelete: l => this.observer.listen('delete', l),
            onLoad: l => this.observer.listen('load', l),
            onChange: l => this.observer.listen('change', l),
        });
    }

    protected fetchNew(): boolean {
        return false;
    }

    protected abstract getCmdPost(): string;

    protected createEntity(): T {
        return {} as T;
    }

    protected abstract buildItems(entity?: T): React.ReactNode;

    protected setTabApi(setTabApi: SetTabApi) {
        setTabApi({
            show: () => {

            },
            hide: () => {

            },
            onDirty: handler => this.dirtyHandler = handler
        })
    }

    protected loadData(id: number) {
        return super.loadData(id).then(x => {
            this._changed = false;
            this.dirtyHandler && this.dirtyHandler(false);
            return x;
        });
    }

    protected dataLoaded(response: any) {
        this.setState(this.responseToState(response));
        this.observer.fire('load', [response.result]);
    }

    protected responseToState(response: any): any {
        const entity = this.createEntity();
        Object.assign(entity, this.responseEntityProp ? response.result[this.responseEntityProp] : response.result);
        if (this.props.copy) {
            entity.Id = 0;
        }

        return { entity };
    }

    protected stateToRequest(): any {
        return this.responseEntityProp ? { [this.responseEntityProp]: this.state.entity } : this.state.entity;
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        return {
            flexDirection: 'row',
            alignItems: 'flex-start',
            overflow: isMobile() ? 'hidden' : undefined,
            ...this.props.sx
        };
    }

    save(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        var ent = this.state.entity;

        var validates: (string | undefined)[] = [];
        for (var vn in this.validators) {
            validates.push(this.validators[vn].validator(ent[this.validators[vn].fieldName]));
        }

        if (validates.find(x => x)) {
            return;
        }

        this.withLoading(() => Proxy.post(this.getCmdPost(), this.stateToRequest())
            .then(x => {
                if (x.success) {
                    ent.Id = x.result as number;
                    this._changed = false;
                    if (this.onSave() !== false) {
                        this.dirtyHandler && this.dirtyHandler(false);
                        this.observer.fire('save', [x.result as number]);
                    }
                }
            }), 1000);
    }

    addValidator(name: string, fieldName: keyof T, validator: (v?: any) => string | undefined) {
        this.validators[name] = {
            fieldName,
            validator
        };
    }

    protected onSave(): boolean | void {
    }

    onChange(e: any) {
        return this.onFieldChange(e.target.name, e.target.type == 'checkbox' ? e.target.checked : e.target.value);
    }

    protected onFieldChange(field: string, value: any) {
        const obj = this.state.entity as any;

        const old = obj[field];
        if (old != value) {
            this._changed = true;
            this.dirtyHandler && this.dirtyHandler(true);
            this.observer.fire('change', [field, value, old, obj]);
        }

        return obj[field] = value;
    }

    renderCallback(callbackWidget: (callback: () => void) => React.ReactNode) {
        this.setState({
            callbackWidget: callbackWidget(() => {
                this.observer.fire('delete', [this.state.entity.Id]);
                this.setState({ callbackWidget: undefined });
            })
        });
    }

    onDelete() {
        this.renderCallback(c => <DeleteDialog
            key={genId('delete') + '-' + this.state.entity.Id}
            deleteText="Удалить"
            onSuccess={() => {
                c();
                this.observer.fire('delete', [this.state.entity.Id])
            }}
            onCancel={() => this.setState({ callbackWidget: undefined })}
            {...this.getDeleteProps()}
        />);
    }

    protected getDeleteProps() {
        return {
            open: true,
            entityId: this.state.entity.Id,
            ...this.props.delete
        } as DeleteDialogProps;
    }

    buildButtons(entity?: T): React.ReactNode[] {
        const result = [this.buildSaveButton()];
        if (entity?.Id) {
            result.push(
                <div style={{ flex: 1 }} />,
                this.buildDeleteButton()
            );
        }

        return result;
    }

    buildSaveButton(text?: ReactNode, props?: ButtonProps) {
        return <Button key="save" variant="contained" type="submit" disabled={!!this.state?.loading} {...props}>{text || 'Сохранить'}</Button>;
    }

    buildDeleteButton(text?: ReactNode, props?: ButtonProps) {
        return <Button key="delete" color="error" variant="outlined" disabled={!!this.state?.loading} onClick={this.onDelete} {...props}>{text || 'Удалить'}</Button>;
    }

    render() {
        const entity = this.state?.entity;
        const buttons = this.buildButtons(entity)

        return <>
            <StyledFormBox key={entity?.Id + '_' + this.props.id} onSubmit={this.save} className="loading-form" sx={this.getFormStyle()}>
                <LinearProgress
                    sx={{ p: 0, m: -2, marginTop: -3 }}
                    style={{ opacity: this.state?.loading == 2 ? 1 : 0, position: "absolute", width: '200%' }} />

                {this.buildItems(entity)}
                {buttons?.length ? <Box sx={{ display: 'flex', marginTop: 2 }}>{buttons}</Box> : null}
            </StyledFormBox>
            {this.state?.callbackWidget}
        </>
    }
}

export abstract class AbstractTypedDataForm<T extends IEntity, TProps = {}, TState = {}> extends AbstractDataForm<T, TProps, TState>{
    protected abstract getTypeId(): string;

    protected getTypeName(): React.ReactNode {
        return '';
    }

    protected getEntityName(): React.ReactNode {
        return (this.state?.entity as any)?.name;
    }

    protected getCmdPost(): string {
        return this.getTypeId() + 'Save';
    }

    protected getCmdGet(): string {
        return this.getTypeId() + 'Get';
    }

    protected getDeleteProps() {
        return {
            typeId: this.getTypeId(),
            typeName: <>объект <b>{this.getTypeName()}</b></>,
            entityName: this.getEntityName(),
            ...super.getDeleteProps()
        } as DeleteDialogProps;
    }
}

export abstract class TypedDataForm<T extends IEntity, TProps extends TypeDataFormProps, TState = {}> extends AbstractTypedDataForm<T, TProps, TState>{
    protected getTypeId() {
        return this.props.typeId;
    }
}