import { Box, Button, Paper, SxProps, Theme } from "@mui/material";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormNumberInput, TextInput } from "../../components/form/TextInput";
import { isOwner, isSuper } from "../../models/Identity";
import { Payment } from "../../models/Types";
import { isMobile } from "../../utils";
import BillCombo, { billStore } from "../bill/BillCombo";
import HouseCombo from "../houses/HouseCombo";
import InhabitantCombo from "../inhabitants/InhabitantCombo";

export function paymentParse(d?: any) {
    const dto = (d || {}) as Payment;
    d?.CreateDate && (dto.CreateDate = new Date(d.CreateDate));
    return dto;
}

export default class PaymentForm extends AbstractTypedDataForm<Payment, {}, { billId?: number }> {
    protected getTypeId(): string {
        return 'Payment';
    }
    protected getTypeName(): React.ReactNode {
        return 'Платеж';
    }

    buildButtons() {
        return [];
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        if (isMobile()) {
            return {
                ...super.getFormStyle(),
                flexDirection: 'column',
                alignItems: 'stretch'
            };
        } else {
            return super.getFormStyle();
        }
    }

    protected onFieldChange(field: string, value: any) {
        if (field === 'BillId') {
            if (value /** && !(this.state.entity as any).Sum /**/) {
                super.onFieldChange('Sum', billStore.data?.find(b => b.Id === value)?.Obj?.Amount);
            }
        }

        if (field === 'BillId' || field === 'HouseId') {
            this.forceUpdate();
        }

        return super.onFieldChange(field, value);
    }

    protected buildItems(entity?: Payment | undefined): React.ReactNode {
        return <>
            <Paper sx={isMobile() ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { minWidth: 500 }} style={{ overflow: 'auto' }}>
                <TextInput
                    required={true}
                    value={entity?.HouseId}
                    label="Дом"
                    name="HouseId"
                    onChange={this.onChange}
                    component={(onChange, error) => <HouseCombo
                        name="HouseId"
                        error={!!error}
                        selectFirstOnLoad={entity && !isOwner() && !entity.Id}
                        value={entity?.HouseId}
                        onChange={onChange} />} />

                <TextInput
                    value={entity?.PayerId}
                    label="Плательщик"
                    name="PayerId"
                    onChange={this.onChange}
                    component={(onChange, error) => <InhabitantCombo
                        name="PayerId"
                        error={!!error}
                        selectFirstOnLoad={entity && !isOwner() && !entity.Id}
                        value={entity?.PayerId}
                        onChange={onChange} />} />

                <TextInput key={'BillId' + entity?.HouseId} 
                    value={entity?.BillId}
                    label="Начисление"
                    name="BillId"
                    onChange={this.onChange}
                    component={(onChange, error) => <BillCombo
                        name="BillId"
                        error={!!error}
                        selectFirstOnLoad={entity && !entity.Id}
                        filter={x => !entity?.HouseId || (x.Obj?.HouseId === entity.HouseId)}
                        value={entity?.BillId}
                        onChange={onChange} />} />

                <FormNumberInput key={'sum' + entity?.BillId} label="Сумма" name="Sum" form={this} entity={entity} />

                <div style={{ flex: 1 }} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    {<Button variant="contained" type="submit">Сохранить</Button>}
                </Box>
            </Paper>
        </>;
    }
}