import route from '../../Router';
import { AddressMap } from '../../components/address/AddressMap';
import { AnalyticFilterFuncs } from '../../components/data/AnalyticFilter';
import { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import { House } from '../../models/Types';
import { HouseDto, HouseForm } from './HouseForm';

const columns: ColumnData<HouseDto>[] = [{
    label: 'Улица',
    name: 'Street',
    filter: true,
    routerLink: true
}, {
    label: '№ дома',
    name: 'Number',
    filter: true
}, {
    label: 'Адрес',
    name: 'Address',
    filter: true
}, {
    label: 'Владелец',
    name: 'OwnerId',
    filter: true
}, {
    label: 'Площадь',
    name: 'Area',
    filter: true
}, {
    label: 'Жильцов',
    name: 'Residents',
    filter: true
}, {
    label: 'Баланс',
    name: 'Balance',
    type: 'numeric',
    filter: true
}];

const analyticFilters: { [key: string]: AnalyticFilterFuncs } = {
    Всего: {
        percent: (d, i, a) => 1
    },
    Домов: {
        percent: (d, i, a) => d?.value ? (d?.sub || 0) / d?.value : 0
    }
};

export default function HouseView() {
    return <DataView<House>
        title="Дом"
        titleMultiple="Дома"
        newTitle="Новый дом"
        typeId="House"
        //guide="locationsGuide"
        /**
        analyticFilter={{
            url: "LocationAnalyticFilter",
            percent: (d, item, all) => analyticFilters[item.key]?.percent ? analyticFilters[item.key].percent!(d, item, all) : 0,
            sub: (x, item) => item.key === 'Домов' ? (x?.sub || 0) + ' домохозяйств' : null,
            value: x => x?.value,
            items: [{
                icon: <LocationOn fontSize="large" />,
                key: 'Всего'
            }, {
                color: "success",
                icon: <HolidayVillage fontSize="large" color="info" />,
                key: 'Домов'
            }]
        }}
        /**/
        modes={[{
            mode: 'list',
            text: <>Список</>
        }, {
            mode: 'board',
            text: <>Карта</>,
            view: () => <AddressMap<HouseDto> cmd="HouseList" />
        }]}
        form={(id, apiRef) => <HouseForm id={id} apiRef={apiRef} lat={route.get('lat')} lon={route.get('lon')} />}
        tableProps={{
            columns: columns
        }} />;
}