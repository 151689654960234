import { Divider, MenuItem, Select, SelectProps } from "@mui/material";
import { periodicServiceTypes } from "../../../models/Enums";
import { Close } from "@mui/icons-material";

export default function PeriodicServiceTypeCombo({ onChange, value, allowClear, ...props }: SelectProps & { allowClear?: boolean }) {
    return <Select {...props}
        defaultValue={value === undefined ? allowClear ? 'null' : '0' : value.toString()}
        onChange={e => {
            const val = parseInt(e.target.value as string);
            e.target.value = isNaN(val) ? undefined : val;
            onChange && onChange(e);
        }}>
        {periodicServiceTypes.map(x => <MenuItem key={x[0]} value={x[0]}>{x[1]}</MenuItem>)}
        {allowClear ? <>
            <Divider key="divider" />
            <MenuItem key="clear" value="null"><Close /> Очистить</MenuItem>
        </> : null}
    </Select>;
}