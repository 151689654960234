import { Breadcrumbs, styled } from "@mui/material";

export const DataBreadcrumbs = styled(Breadcrumbs)`
    margin-bottom: 1rem;
    & li > * {
        font-weight: 400;
        font-size: 1.5rem;
        color: rgba(0, 0, 0, 0.87);
    }
    & li:not(:last-child) {
        cursor: pointer;
    }
`;