import { Box, Button, Paper, SxProps, Theme } from "@mui/material";
import { LatLng } from "leaflet";
import React from "react";
import { AddressApiRef, AddressPanel } from "../../components/address/AddressPanel";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput } from "../../components/form/TextInput";
import { LocationType } from "../../models/Enums";
import { Location } from "../../models/Types";
import LocationTypeCombo from "./LocationTypeCombo";
import { ILocation } from "../../components/address/common";
import { isMobile } from "../../utils";

const services: { [type: keyof LocationType]: string } = {
    [LocationType.House]: 'House'
};

export class LocationForm extends AbstractTypedDataForm<Location, ILocation, {}> {
    private typedLocation?: any
    private addressRef?: AddressApiRef;

    protected getTypeName(): React.ReactNode {
        return 'Локация';
    }

    componentWillReceiveProps(n: Readonly<{ id: number } & ILocation>, nextContext: any): void {
        super.componentWillReceiveProps(n, nextContext);
        const p = this.props;
        if (p.id == n.id && n.lat && n.lon && (p.lat != n.lat || p.lon != n.lon)) {
            this.addressRef?.setLatLon(new LatLng(n.lat, n.lon));
        }
    }

    clear(): void {
        this.addressRef?.clear();
        super.clear();
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        if (isMobile()) {
            return {
                ...super.getFormStyle(),
                display: 'block',
                overflow: 'auto'
            };
        }

        return {
            ...this.props.sx,
            flexDirection: 'row',
            alignItems: 'flex-start'
        };
    }

    buildButtons() {
        return [];
    }

    protected getCmdPost(): string {
        return (this.state.entity?.Type && services[this.state.entity?.Type] || this.getTypeId()) + 'Save';
    }

    protected getTypeId(): string {
        return 'Location';
    }

    protected createEntity(): Location {
        return new Location();
    }

    protected stateToRequest(): any {
        switch (this.state.entity?.Type) {
            case LocationType.House:
                return {
                    Entity: {
                        Number: '0',
                        Street: 'pp',
                        ...this.typedLocation
                    },
                    Location: this.state.entity
                }

            default: return super.stateToRequest();
        }
    }

    protected buildItems(entity?: Location | undefined): React.ReactNode {
        return <>
            <Paper sx={{ minWidth: 500 }}>
                {entity && <AddressPanel entity={entity} apiRef={x => this.addressRef =x } />}
            </Paper>

            <Paper sx={{ minWidth: 500 }}>
                <FormTextInput required={true} label="Наименование" name="Name" form={this} entity={entity} />

                <LocationTypeCombo label="Тип" name="Type" value={entity?.Type} onChange={this.onChange} />

                <FormTextInput form={this} entity={entity} label="Примечания по схеме проезда, прохода к локации" name="Description" multiline={true} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <Button variant="contained" type="submit">Сохранить</Button>
                </Box>
            </Paper>

            {entity?.Type === LocationType.Camera ? <div dangerouslySetInnerHTML={{ __html: entity.Description }} /> : null}
        </>;
    }
}