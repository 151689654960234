import { ElectricMeter, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material';
import { CircularProgressItem, ComponentItem, styles } from '../../components/data/AnalyticFilter';
import { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import { Measure } from '../../models/Types';
import HouseCombo, { houseStore } from '../houses/HouseCombo';
import { MeasureForm, measureParse } from './MeasureForm';
import MeterTariffCombo, { meterTariffStore } from './MeterTariffCombo';
import NavLink from '../../components/NavLink';
import { Box } from '@mui/material';

const columns: ColumnData<Measure>[] = [{
    label: 'Ресурс',
    name: 'MeterTariffId',
    routerLink: true,
    store: meterTariffStore,
    filter: (onChange) => <MeterTariffCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => meterTariffStore.data?.find(x => x.Id == val)?.Value
}, {
    label: 'Дата снятия',
    name: 'ReadDate',
    filter: true,
    type: 'date',
}, {
    label: 'Дата передачи',
    name: 'CreateDate',
    filter: true,
    type: 'date',
}, {
    label: 'Дом',
    name: 'HouseId',
    store: houseStore,
    filter: (onChange) => <HouseCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => houseStore.data?.find(x => x.Id == val)?.Value
}, {
    label: 'Показание',
    name: 'Value',
    type: 'numeric',
    filter: true
}];

export default function MeasureView() {
    return <DataView<Measure>
        analyticFilter={{
            url: "MeasureAnalytic",
            items: data => {
                const meters = data.Meters as any;
                var percent = (1 - meters.Overdue / meters.All) * 100;
                const color = percent < 50 ? 'error' : percent == 100 ? 'success' : 'warning';

                const result = [
                    <CircularProgressItem data={data} item={{
                        key: 'Meters',
                        icon: <ElectricMeter color={color} />,
                        color
                    }} funcs={{
                        percent: () => percent,
                        sub: () => <>Не подано: {meters.Overdue}</>,
                        value: () => <>Всего: {meters.All}</>,
                        title: () => <>Счетчики</>
                    }} />
                ];

                (data?.Measures as any[])?.map(x => <ComponentItem
                    component={(cur, props) => <Box sx={styles.componentWrapper}>
                        {props.data.Cur > props.data.Prev ?
                            <KeyboardDoubleArrowUp fontSize="large" color="error" /> :
                            props.data.Cur < props.data.Prev ?
                                <KeyboardDoubleArrowDown fontSize="large" color="success" /> :
                                null}
                    </Box>}
                    data={x}
                    item={{
                        key: 'MeterTariffId',
                        icon: <ElectricMeter color={color} />,
                        color
                    }}
                    funcs={{
                        title: (c, i, d) => meterTariffStore.data?.find(x => x.Id === d.MeterTariffId)?.Value,
                        value: (c, i, d) => 'Предыдущее: ' + d.Prev,
                        sub: (c, i, d) => <NavLink route={[['hideForm'], ['id', 'new'], ['MeterTariffId', d.MeterTariffId]]} fontWeight="bold" color={d.Cur ? undefined : 'error'}>
                            {d.Cur || 'Подать показания'}
                        </NavLink>,
                    }} />).forEach(x => result.push(x));

                return result;
            }
        }}
        title="Показание"
        titleMultiple="Показания"
        typeId="Measure"
        form={(id, apiRef) => <MeasureForm key="main" id={id} apiRef={apiRef} />}
        tableProps={{ columns, dataParser: measureParse }} />;
}