import { Box, CircularProgress, Divider, Paper, Typography } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { useRemoteData } from '../../models/Hooks';
import { isFunc, isMobile } from '../../utils';

export interface AnalyticFilterValue {
    value?: any
    sub?: any
}

export interface AnalyticFilterDto {
    [key: string]: AnalyticFilterValue
}

export interface AnalyticFilterItem<T extends AnalyticFilterDto = AnalyticFilterDto> {
    icon: ReactNode,
    color?: string,
    key: keyof T
    title?: string
}

export interface AnalyticFilterFuncs<T extends AnalyticFilterDto = AnalyticFilterDto> {
    title?: (current: AnalyticFilterValue | null, item: AnalyticFilterItem<T>, data: T | null) => ReactNode
    sub?: (current: AnalyticFilterValue | null, item: AnalyticFilterItem<T>, data: T | null) => ReactNode
    value?: (current: AnalyticFilterValue | null, item: AnalyticFilterItem<T>, data: T | null) => ReactNode
    percent?: (current: AnalyticFilterValue | null, item: AnalyticFilterItem<T>, data: AnalyticFilterDto) => number
}

export interface AnalyticFilterProps<T extends AnalyticFilterDto> extends AnalyticFilterFuncs<T> {
    url: string
    items: AnalyticFilterItem<T>[] | ((data: T) => ReactNode[])
}

interface ItemProps<T extends AnalyticFilterDto> {
    data: T,
    item: AnalyticFilterItem<T>,
    funcs?: AnalyticFilterFuncs<T>
}

export function ComponentItem<T extends AnalyticFilterDto>({ component, data, item, funcs }: { component: (current:T[keyof T], props: ItemProps<T>) => ReactNode } & ItemProps<T>) {
    const current = data && data[item.key];
    const ism = isMobile();

    return <Box sx={{ flex: 1, display: 'flex', justifyContent: ism ? 'flex-start' : 'center', gap: 1, py: ism ? 2 : 0 }}>
        {component(current, { data, item, funcs })}
        <Box>
            <Typography variant="h6" fontSize="1rem">{funcs?.title ? funcs?.title(current, item, data) : (item.title || item.key as string)}</Typography>
            <Typography height="1.2em" lineHeight={1.2} fontSize="0.875rem">{funcs?.sub ? funcs?.sub(current, item, data) : current?.sub || '-'}</Typography>
            <Typography variant="h6" fontSize="0.875rem">{funcs?.value ? funcs?.value(current, item, data) : current?.value || '-'}</Typography>
        </Box>
    </Box>;
}

export function CircularProgressItem<T extends AnalyticFilterDto>(props: ItemProps<T>) {
    return <ComponentItem {...props}
        component={(current, props) => <Box sx={{
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
            width: '4rem',
            justifyContent: 'center'
        }}>
            {props.funcs?.percent && <CircularProgress
                variant="determinate"
                color={props.item.color as any}
                value={props.data ? props.funcs?.percent(current, props.item, props.data) : 0}
                size='3rem'
            />}
            <Box sx={styles.componentWrapper}
                style={props.funcs?.percent ? { position: 'absolute' } : undefined}
            >{props.item.icon}</Box>
        </Box>} />;
}

export default function AnalyticFilter<T extends AnalyticFilterDto>({ url, items, ...funcs }: AnalyticFilterProps<T>) {
    const [data] = useRemoteData<T>(url);


    const body = useMemo(() => {
        const ism = isMobile();
        const blocks = data ? isFunc(items) ? (items as ((data: T) => ReactNode[]))(data) :
            (items as AnalyticFilterItem<T>[]).map(x => <CircularProgressItem data={data} item={x} funcs={funcs} />) : [];

        return blocks?.map((x, i) => i ? <><Divider orientation={ism ? 'horizontal' : 'vertical'} />{x}</> : x);
    }, [data]);

    return <Paper sx={{ display: 'flex', p: 2, flexDirection: isMobile() ? 'column' : 'row' }}>
        {body}
    </Paper>;
}

export const styles = {
    componentWrapper: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '4rem'
    }
}