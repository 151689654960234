import React from "react";
import Proxy from "../../models/Proxy";
import Observer from "../../models/Observer";

interface ILoadingFormState {
    loading: 0 | 1 | 2,
    id?: number
}

export abstract class LoadingForm<TProps = {}, TState = {}> extends React.Component<TProps & { id: number }, TState & ILoadingFormState> {
    protected unlisten: Function[]
    protected observer: Observer

    constructor(p: any) {
        super(p);

        this.unlisten = [];
        this.observer = new Observer();
    }

    componentDidMount(): void {
        if ((this.props.id as number) != (this.state?.id as number)) {
            this.loadData(this.props.id);
        }
    }

    componentWillReceiveProps(nextProps: Readonly<{ id: number; }>, nextContext: any): void {
        if (this.props.id != nextProps?.id) {
            requestAnimationFrame(() => this.loadData(nextProps?.id));
        }
    }

    componentWillUnmount(): void {
        this.unlisten.forEach(x => x());
    }

    clear() {
        this.setState({ id: 0 });
    }

    setState(state: any, callback?: () => void) {
        return super.setState(state, callback);
    }

    /**
     * Запрашивать ли данные для нового объекта (id = 0)
     * @returns boolean
     */
    protected fetchNew() {
        return true;
    }

    protected getLoadParams(id: number): any {
        return { id };
    }

    protected loadData(id: number) {
        if (id || this.fetchNew()) {
            return this.withLoading
                (() => Proxy.get(this.getCmdGet(), this.getLoadParams(id))
                    .then(x => this.setState({ id, ...this.dataLoaded(x) })),
                    500);
        } else {
            return Promise.resolve(this.clear());
        }
    }

    protected abstract getCmdGet(): string;

    protected abstract dataLoaded(response: any): any

    protected withLoading<T>(fn: () => Promise<T>, timeout: number = 500) {
        !this.state?.loading && this.setState({ loading: 1 } as any);
        const tid = setTimeout(() => this.setState({ loading: 2 } as any), timeout);
        return fn().then(x => {
            clearTimeout(tid);
            this.setState({ loading: 0 } as any);

            return x
        })
    }
}