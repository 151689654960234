import { Button, Chip } from '@mui/material';
import { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import { Bill } from '../../models/Types';
import { periodNumToText } from '../../utils';
import HouseCombo, { houseStore } from '../houses/HouseCombo';
import { BillForm, billParse } from './BillForm';
import Proxy from '../../models/Proxy';
import { ListApiRef } from '../../components/data/ApiRef';
import { useRef } from 'react';

export const columns: ColumnData<Bill>[] = [{
    name: "CreateDate",
    label: 'Дата',
    type: 'date'
}, {
    label: 'Период',
    name: 'PeriodNum',
    renderer: periodNumToText,
    filter: true,
    filterFn: (s, o) => (o.PeriodNum.toString().indexOf(s) >= 0) || (periodNumToText(o?.PeriodNum)?.indexOf(s) as number >= 0),
    width: 150
}, {
    label: 'Дом',
    name: 'HouseId',
    routerLink: true,
    store: houseStore,
    filter: (onChange) => <HouseCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => houseStore.data?.find(x => x.Id == val)?.Value
}, {
    label: 'Сумма',
    name: 'Amount',
    type: 'numeric',
    filter: true
}, {
    label: 'Статус',
    name: 'PaymentId',
    width: 10,
    renderer: x => <Chip size="small" color={x ? 'success' : 'error'} label={(x ? 'О' : 'Не о') + 'плачено'} />
}];

export const psBillCollumns = columns.filter(x => x.name !== 'PeriodNum').map(x => x.routerLink ? { ...x, routerLink: false } : x);

export default function BillView() {
    const listApi = useRef<ListApiRef<Bill>>();
    const periods = useRef<{
        CurPeriod: number
        PrevPeriod: number
    }>();

    function recalculateAll() {
        if (periods.current) {
            Proxy.post('BillRecalcualteAll?period=' + periods.current.CurPeriod, false).then(x => {
                debugger;
                listApi.current?.load();
            });
        }
    }

    return <DataView<Bill>
        /**analyticFilter={{
            url: "MeasureAnalytic",
            items: data => {
                const meters = data.Meters as any;
                var percent = (1 - meters.Overdue / meters.All) * 100;
                const color = percent < 50 ? 'error' : percent == 100 ? 'success' : 'warning';

                const result = [
                    <CircularProgressItem data={data} item={{
                        key: 'Meters',
                        icon: <ElectricMeter color={color} />,
                        color
                    }} funcs={{
                        percent: () => percent,
                        sub: () => <>Не подано: {meters.Overdue}</>,
                        value: () => <>Всего: {meters.All}</>,
                        title: () => <>Счетчики</>
                    }} />
                ];

                (data?.Measures as any[])?.map(x => <ComponentItem
                    component={(cur, props) => <Box sx={styles.componentWrapper}>
                        {props.data.Cur > props.data.Prev ?
                            <KeyboardDoubleArrowUp fontSize="large" color="error" /> :
                            props.data.Cur < props.data.Prev ?
                                <KeyboardDoubleArrowDown fontSize="large" color="success" /> :
                                null}
                    </Box>}
                    data={x}
                    item={{
                        key: 'MeterTariffId',
                        icon: <ElectricMeter color={color} />,
                        color
                    }}
                    funcs={{
                        title: (c, i, d) => meterTariffStore.data?.find(x => x.Id === d.MeterTariffId)?.Value,
                        value: (c, i, d) => 'Предыдущее: ' + d.Prev,
                        sub: (c, i, d) => <NavLink route={[['hideFom'], ['id', 'new'], ['MeterTariffId', d.MeterTariffId]]} fontWeight="bold" color={d.Cur ? undefined : 'error'}>
                            {d.Cur || 'Подать показания'}
                        </NavLink>,
                    }} />).forEach(x => result.push(x));

                return result;
            }
        }}/**/
        title="Начисление"
        titleMultiple="Начисления"
        typeId="Bill"
        append={() => <Button variant="outlined" onClick={recalculateAll}>Пересчитать текущий период</Button>}
        viewSx={{
            flexDirection: 'column'
        }}
        form={(id, apiRef) => <BillForm key="main" id={id} apiRef={apiRef} />}
        tableProps={{
            apiRef: x => listApi.current = x,
            columns,
            onAfterLoad: (d, x) => periods.current = { CurPeriod: x.result.CurPeriod, PrevPeriod: x.result.PrevPeriod },
            dataParser: billParse
        }} />;
}