import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useState } from "react";

export interface EnumComboProps<TEnum> {
    label?: string
    multiple?: boolean
    name?: string
    value?: (TEnum | TEnum[])
    onChange?: (e: SelectChangeEvent<any>, child: React.ReactNode) => void
}

export default function EnumCombo<TEnum>({ multiple, value, onChange, name, enumNames, label }: EnumComboProps<TEnum> & { enumNames: { [name: number]: string } }) {
    const [v, sv] = useState(value);

    const items = [];
    for (var ot in enumNames) {
        items.push(<MenuItem key={ot} value={ot.toString()}>{(enumNames as any)[ot]}</MenuItem>);
    }

    function setValue(e: SelectChangeEvent<any>, child: React.ReactNode) {
        var value = Array.isArray(e.target.value) ?
            (e.target.value as []).map(x => parseInt(x)) as TEnum[] :
            (parseInt(e.target.value as string) || 0) as TEnum;

        e.target.value = value;

        onChange && onChange(e, child);

        sv(value);
    }

    return <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select
            label={label}
            name={name}
            SelectDisplayProps={{ style: { display: 'flex' } }}
            value={multiple ? (v as number[]).map(x => x.toString()) : (v?.toString() || '')}
            onChange={setValue}>
            {items}
        </Select>
    </FormControl>;
}