import * as React from 'react';

export default class ObservableComponent<P = {}, S = {}> extends React.Component<P, S> {
    protected _unlisten: Function[]

    constructor(p: any) {
        super(p);

        this._unlisten = [];
    }

    componentWillUnmount(): void {
        this._unlisten?.forEach(x => x());
    }
}