import { LatLngTuple, Map, latLngBounds } from "leaflet";
import { useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import route from "../../Router";
import Proxy from "../../models/Proxy";
import { IEntity, IHasAddress } from "../../models/Types";
import { defaultCenter, markerIcon } from "./common";

export function AddressMap<T extends IHasAddress & IEntity>({ cmd }: { cmd: string }) {
    const [data, setData] = useState<T[]>();
    const [map, setMap] = useState<Map>();
    const [loading, setLoading] = useState(false);
    const [center, setCenter] = useState<LatLngTuple | undefined>(defaultCenter);

    if (!data && !loading) {
        setLoading(true);

        Proxy.get(cmd)
            .then(x => {
                setLoading(false);

                var data = (x.result.Data as []).map((d: any) => Object.assign({} as IHasAddress, d));
                setData(data);

                fitMarkers();
            });
    }

    function _setMap(map: Map | null) {
        setMap(map || undefined);
        setCenter(undefined);
        map?.on('click', e => route.setState([['id', 'new'], ['lat', e.latlng.lat], ['lon', e.latlng.lng]]));
        fitMarkers();
    }

    function fitMarkers() {
        if (map && data?.length) {
            let markerBounds = latLngBounds([]);
            data.forEach(x => x.Lat && x.Lon && markerBounds.extend([x.Lat, x.Lon]))
            markerBounds.isValid() && map.fitBounds(markerBounds);
        }
    }

    return <MapContainer style={styles.map} ref={_setMap} center={center} zoom={10}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {data?.filter(x => x.Lat && x.Lon).map(x => <Marker position={[x.Lat!, x.Lon!]} icon={markerIcon} eventHandlers={{ click: () => route.setState('id', x.Id) }} />)}
    </MapContainer>;
}

const styles = {
    map: {
        flex: 1,
        overflow: 'hidden',
        height: '100%'
    }
}