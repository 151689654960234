import { LatLngTuple, Map, divIcon, icon, latLngBounds } from "leaflet";
import markerIconUrl from "leaflet/dist/images/marker-icon.png";
import markerShadowUrl from "leaflet/dist/images/marker-shadow.png";
import { useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import route from "../../Router";
import { LocationType } from "../../models/Enums";
import Proxy from "../../models/Proxy";
import { Location } from "../../models/Types";

const markerIcons: any = {
    [LocationType.Camera]: divIcon({
        html: '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1iirmgg" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="VideocamIcon"><path fill="#4caf50" d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11z"></path></svg>',
        iconSize: [36, 36],
        iconAnchor: [18, 36],
        className: 'marker-icon MuiPaper-elevation1'
    }),
    default: icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        iconUrl: markerIconUrl,
        shadowUrl: markerShadowUrl
    })
};

const defaultCenter: LatLngTuple = [55.794438, 49.111451];

export function LocationMap() {
    const [data, setData] = useState<Location[]>();
    const [map, setMap] = useState<Map>();
    const [loading, setLoading] = useState(false);
    const [center, setCenter] = useState<LatLngTuple | undefined>(defaultCenter);

    if (!data && !loading) {
        setLoading(true);

        Proxy.get('LocationList')
            .then(x => {
                setLoading(false);

                var data = (x.result.Data as []).map((d: any) => Object.assign(new Location(), d) as Location);
                setData(data);

                fitMarkers();
            });
    }

    function _setMap(map: Map | null) {
        setMap(map || undefined);
        setCenter(undefined);
        map?.on('click', e => route.setState([['id', 'new'], ['lat', e.latlng.lat], ['lon', e.latlng.lng]]));
        fitMarkers();
    }

    function fitMarkers() {
        if (map && data?.length) {
            let markerBounds = latLngBounds([]);
            data.forEach(x => x.Lat && x.Lon && markerBounds.extend([x.Lat, x.Lon]))
            markerBounds.isValid() && map.fitBounds(markerBounds);
        }
    }

    return <MapContainer style={styles.map} ref={_setMap} center={center} zoom={10}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {data?.filter(x => x.Lat && x.Lon).map(x => <Marker
            position={[x.Lat!, x.Lon!]}
            icon={markerIcons[x.Type] || markerIcons.default}
            eventHandlers={{ click: () => route.setState('id', x.Id) }} />)}
    </MapContainer>;
}

const styles = {
    map: {
        flex: 1,
        overflow: 'hidden',
        height: '100%'
    }
}