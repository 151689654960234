import { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import { EntryPass } from '../../models/Types';
import HouseCombo, { houseStore } from '../houses/HouseCombo';
import { EntryPassForm, entryPassParse } from './EntryPassForm';

const columns: ColumnData<EntryPass>[] = [{
    label: 'Рег. номер',
    name: 'CarNumber',
    routerLink: true
}, {
    label: 'Активен до',
    name: 'Expire',
    filter: true,
    type: 'date',
}, {
    label: 'Дом',
    name: 'HouseId',
    store: houseStore,
    filter: (onChange) => <HouseCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => houseStore.data?.find(x => x.Id == val)?.Value
}, {
    label: 'Наименование',
    name: 'Name',
    routerLink: true
}, {
    label: 'Марка',
    name: 'CarModel'
}];

export default function EntryPassView() {
    return <DataView<EntryPass>
        title="Пропуск"
        titleMultiple="Пропуска"
        typeId="EntryPass"
        form={(id, apiRef) => <EntryPassForm key="main" id={id} apiRef={apiRef} />}
        tableProps={{ columns, dataParser: entryPassParse }} />;
}