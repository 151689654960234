import { Box, Button, Paper, SxProps, Theme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormNumberInput, TextInput } from "../../components/form/TextInput";
import { Measure } from "../../models/Types";
import HouseCombo from "../houses/HouseCombo";
import MeterTariffCombo, { meterTariffStore } from "./MeterTariffCombo";
import { isMobile } from "../../utils";
import route from "../../Router";

route.register('MeterTariffId', undefined, undefined, parseInt);

export function measureParse(d?: any) {
    const dto = (d || {}) as Measure;
    d?.CreateDate && (dto.CreateDate = new Date(d.CreateDate));
    d?.ReadDate && (dto.ReadDate = new Date(d.ReadDate));
    return dto;
}

export class MeasureForm extends AbstractTypedDataForm<Measure, {}, { houseId?: number }> {
    protected getTypeId(): string {
        return 'Measure';
    }
    protected getTypeName(): React.ReactNode {
        return 'Показание';
    }

    buildButtons() {
        return [];
    }

    protected createEntity() {
        const result = measureParse();
        result.MeterTariffId = route.get<number>('MeterTariffId');
        return result;
    }

    protected onFieldChange(field: string, value: any) {
        if (field === 'HouseId') {
            this.setState({ houseId: value as number || undefined });
        }

        return super.onFieldChange(field, value);
    }

    protected getFormStyle(): SxProps<Theme> | undefined {
        if (isMobile()) {
            return {
                ...super.getFormStyle(),
                flexDirection: 'column',
                alignItems: 'stretch'
            };
        } else {
            return super.getFormStyle();
        }
    }

    protected buildItems(entity?: Measure | undefined): React.ReactNode {
        return <>
            <Paper sx={isMobile() ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { minWidth: 500 }} style={{ overflow: 'auto' }}>
                {/*<Box display="flex" flexDirection="column" gap={2} overflow="auto">*/}
                <TextInput
                    value={entity?.HouseId}
                    label="Дом"
                    name="HouseId"
                    onChange={this.onChange}
                    component={(onChange, error) => <HouseCombo
                        name="HouseId"
                        error={!!error}
                        selectFirstOnLoad={entity && !entity.Id}
                        filter={x => !!meterTariffStore.data?.find(mt => mt.Obj?.HouseId === x.Id)}
                        value={entity?.HouseId}
                        onChange={onChange} />} />

                <TextInput
                    required={true}
                    value={entity?.MeterTariffId}
                    label="Ресурс"
                    name="MeterTariffId"
                    onChange={this.onChange}
                    validator={v => v ? undefined : 'Необходимо выбрать ресурс'}
                    refValidator={val => this.addValidator('MeterTariffId', 'MeterTariffId', v => val(v))}
                    component={(onChange, error) => <MeterTariffCombo
                        name="MeterTariffId"
                        error={!!error}
                        filter={x => !this.state?.houseId || (x.Obj?.HouseId === this.state.houseId)}
                        value={entity?.MeterTariffId}
                        onChange={onChange} />} />

                <DatePicker label="Дата подачи" value={moment(entity?.CreateDate)} readOnly />
                <DatePicker label="Дата снятия" value={moment(entity?.ReadDate)} onChange={v => entity!.ReadDate = v?.toDate() || new Date()} />

                <FormNumberInput label="Значение" name="Value" form={this} entity={entity} />
                {/*</Box>*/}

                <div style={{ flex: 1 }} />

                <Box sx={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    {<Button variant="contained" type="submit">Сохранить</Button>}
                </Box>
            </Paper>
        </>;
    }
}