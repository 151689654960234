import RemoteCombo, { configureSuggestStore, RemoteComboProps } from "../../components/form/RemoteCombo";
import { Currency } from "../../models/Types";

const cmd = 'CurrencyList';
const valueField = 'name';

export const currencyStore = configureSuggestStore<Currency>(cmd, valueField, 30 * 60);

export default function CurrencyCombo(props: RemoteComboProps<Currency>) {
    return <RemoteCombo<Currency>
        typeId="Currency"
        valueField={valueField}
        store={currencyStore}
        cmd={cmd}
        {...props}
        ref={undefined} />;
}