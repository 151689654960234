import { FormControl, InputLabel, OutlinedInput, OutlinedInputProps } from "@mui/material";
import { LatLng, LatLngTuple, Map } from "leaflet";
import { useEffect, useRef, useState } from "react";
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { IHasAddress } from "../../models/Types";
import { markerIcon, styles } from "./common";

function AddressOutlinedInput({ onChange, ...props }: OutlinedInputProps) {
    return <OutlinedInput onChange={e => {
        e.target.parentElement?.setAttribute('value', e.target.value);
        onChange && onChange(e);
    }} {...props} />;
}

const defaultCenter: LatLngTuple = [55.794438, 49.111451];

export interface AddressApiRef {
    clear: () => any,
    setLatLon: (latlng: LatLng) => any
}

export function AddressPanel<T extends IHasAddress>({ entity, apiRef, onAdressChange }: { entity: T, apiRef?: (api: AddressApiRef) => any, onAdressChange?: (s?: DaDataSuggestion<DaDataAddress>) => any }) {
    const mapRef = useRef<Map>();
    const addressRef = useRef<DaDataSuggestion<DaDataAddress>>();
    const [k, sk] = useState(0);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) =>
            mapRef.current?.setView([position.coords.latitude, position.coords.longitude], 13));
    });

    useEffect(() => {
        if (entity) {
            addressRef.current = {
                value: entity.Address || '',
                unrestricted_value: entity.SuggestAddress || '',
                data: {
                    geo_lat: entity.Lat?.toString() || null,
                    geo_lon: entity.Lon?.toString() || null
                }
            } as any;

            sk(k + 1);

            requestAnimationFrame(() => {
                if (mapRef.current) {
                    let ll = getLLFromAddress();
                    if (ll) {
                        mapRef.current?.setView(ll, 15);
                    }
                }
            });
        }

        apiRef && apiRef({
            clear: () => addressRef.current = undefined,
            setLatLon
        });
    }, [entity])

    function adressChangeHandler(s?: DaDataSuggestion<DaDataAddress>) {
        addressRef.current = s;

        applyMapEntity(entity) && sk(k + 1);
    }

    function applyMapEntity(entity: T) {
        if (addressRef.current) {
            entity.Address = addressRef.current.value;
            entity.SuggestAddress = addressRef.current.unrestricted_value;

            const ll = getLLFromAddress(entity);
            if (ll) {
                entity.Lat = ll[0];
                entity.Lon = ll[1];

                mapRef.current?.setView(ll, 15);
            }

            onAdressChange && onAdressChange(addressRef.current);

            return true;
        }
    }

    function onMapRef(map?: Map | null) {
        if (mapRef.current = (map || undefined)) {
            map!.on('click', e => setLatLon(e.latlng));
        }
    }

    function setLatLon(latlng: LatLng) {
        entity.Address = undefined;
        entity.SuggestAddress = undefined;
        addressRef.current = undefined;
        applyMapEntity(entity);

        const coords = {
            lat: latlng.lat,
            lon: latlng.lng
        }

        Object.assign(entity, coords);
        sk(k + 1);

        fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address', {
            method: 'post',
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token 6cc7b3db9c2133e72e059ccd0f58d553e283297d"
            },
            body: JSON.stringify(coords)
        })
            .then(x => x.json())
            .then(x => {
                addressRef.current = x.suggestions[0];
                if (addressRef.current) {
                    addressRef.current.data.geo_lat = coords.lat.toString();
                    addressRef.current.data.geo_lon = coords.lon.toString();
                    applyMapEntity(entity);
                    Object.assign(entity, coords);
                    sk(k + 1);
                }
            });
    }

    function getLLFromAddress(entity?: T): LatLngTuple | undefined {
        const d = addressRef.current?.data;
        if (d && d.geo_lat && d.geo_lon) {
            return [parseFloat(d.geo_lat), parseFloat(d.geo_lon)];
        } else if (entity && entity.Lat && entity.Lon) {
            return [entity.Lat, entity.Lon];
        }
    }

    const ll = getLLFromAddress(entity);

    return <>
        <FormControl sx={{ zIndex: 1024 }}>
            <InputLabel>Адрес</InputLabel>
            <AddressSuggestions
                customInput={AddressOutlinedInput}
                token="6cc7b3db9c2133e72e059ccd0f58d553e283297d"
                value={addressRef.current}
                onChange={adressChangeHandler} />
        </FormControl>

        <MapContainer ref={onMapRef} style={styles.map} center={ll || defaultCenter} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {ll && <Marker position={ll} icon={markerIcon}><Popup>{addressRef.current?.unrestricted_value}</Popup></Marker>}
        </MapContainer>
    </>;
}