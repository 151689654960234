import { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import { Meter } from '../../models/Types';
import HouseCombo, { houseStore } from '../houses/HouseCombo';
import TariffCombo, { tariffStore } from '../project/tariff/TariffCombo';
import { MeterForm, meterParse } from './MeterForm';

const columns: ColumnData<Meter>[] = [{
    label: 'Номер',
    name: 'Number',
    filter: true,
    routerLink: true
}, {
    label: 'Наименование',
    name: 'Name',
    filter: true,
    routerLink: true
}, {
    label: 'Дом',
    name: 'HouseId',
    store: houseStore,
    filter: (onChange) => <HouseCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => houseStore.data?.filter(x => x.Id == val).map(x => x.Value)[0]
}, {
    label: 'Тарифы',
    name: 'Tariffs',
    type: 'array',
    store: tariffStore,
    filter: (onChange) => <TariffCombo onChange={e => onChange(e.target.value)} />,
    renderer: (val, obj) => (val as number[])?.map(v => tariffStore.data?.filter(x => x.Id == v).map(x => x.Value)).join(', ')
}, {
    label: 'Дата поверки',
    name: 'CheckDate',
    type: 'date',
    filter: true
}, {
    label: 'Следующая поверка',
    name: 'NextCheck',
    type: 'date',
    width: 210,
    filter: true
}];

export default function MeterView() {
    return <DataView<Meter>
        title="Счетчик"
        titleMultiple="Счетчики"
        typeId="Meter"
        form={(id, apiRef) => <MeterForm key="main" id={id} apiRef={apiRef} />}
        tableProps={{ columns, dataParser: meterParse }} />;
}