import { months } from "moment";
import { User } from "./models/Types";

const funcType = typeof (function () { });
const objType = typeof ({});
const strType = typeof '';

export const isFunc = (func: any) => typeof func == funcType;
export const isString = (str: any) => typeof str == strType;

export const objectAssign = function (obj: any) {
    if (!obj) {
        return obj;
    }

    for (var i = 1; i < arguments.length; i++) {
        var c = arguments[i];
        if (c && typeof c === objType) {
            for (var p in c) {
                obj[i] = c[i];
            }
        }
    }

    return obj;
}

export const clone = (x: any) => x ? JSON.parse(JSON.stringify(x)) : x;

export const decorate = (x: any, mtd: string, decorator: ((mtd: Function) => Function)) => {
    var _mtd = x.prototype[mtd];
    return x.prototype[mtd] = decorator(_mtd);
}

export const userDisplayName = (user: User) => {
    if (user) {
        return user.Name;
    }
}

export function isMobile() {
    return document.body.offsetWidth < 1024;
}

export function findInSearch(key: string) {
    return document.location.search.substring(1).split('&')
        .map(x => x.split('='))
        .filter(x => x[0] == key)
        .map(x => x.length > 1 ? x[1] : null)[0];
}

const genids: { [prefix: string]: number } = {}
export function genId(prefix: string) {
    const next = genids[prefix] = (genids[prefix] || 0) + 1;
    return prefix + '-' + next;
}

export function arrayReplace<T>(array?: T[], predicate?: (x: T) => boolean, ...insert: T[]) {
    if (!array) {
        return;
    }

    let i = predicate ? array.findIndex(predicate) : -1;
    return i >= 0 ? array.splice(i, 1, ...insert) : array.push(...insert);
}

var today = new Date();
today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
function nextToday() {
    const nextDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    setTimeout(() => {
        today = nextDate;
        nextToday();
    }, nextDate.getTime() - new Date().getTime());
}
nextToday();
export const getToday = () => today;

const _nf = Intl.NumberFormat();
export function numberFormat(num?: number, unit?: any) {
    return num ? unit ? _nf.format(num) + unit : _nf.format(num) : num;
}

export const monthNames = months();
export function periodNumToText(periodNum?: number) {
    if (periodNum && periodNum > 200000) {
        return monthNames[(periodNum % 100 - 1) as number] + ' ' + parseInt(periodNum / 100 as any);
    }
}