import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { Contact } from "../../models/Types";
import { DataGrid, DataGridApiProps } from "../form/DataGrid";
import './person.css';

export function Contacts({ contacts, ...props }: { contacts: Contact[] } & DataGridApiProps<Contact>) {
    return <DataGrid<Contact> 
        {...props}
        label="Контакты"
        addText="контакт"
        create={() => { return {}; }}
        entities={contacts}
        row={(c, i) => <>
            <Select defaultValue={c.Type} placeholder="Тип" onChange={e => c.Type = e.target.value}>
                <MenuItem value="Phone">Телефон</MenuItem>
                <MenuItem value="Email">E-mail</MenuItem>
                <MenuItem value="Link">Ссылка</MenuItem>
                <MenuItem value="Telegram">Telegram</MenuItem>
                <MenuItem value="Instagram">Instagram</MenuItem>
            </Select>
            <OutlinedInput onChange={e => c.Value = e.target.value} defaultValue={c.Value} />
            <OutlinedInput onChange={e => c.Description = e.target.value} defaultValue={c.Description} placeholder="Примечание" />
        </>} />;
}